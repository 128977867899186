interface Action {
  payload: any;
  type: any;
  error?: any;
}

let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")!)
  : "";
let token = localStorage.getItem("tokens")
  ? JSON.parse(localStorage.getItem("tokens")!)
  : "";
let lookups = localStorage.getItem("lookups")
  ? JSON.parse(localStorage.getItem("lookups")!)
  : "";
let lang = localStorage.getItem("i18nextLng")
  ? localStorage.getItem("i18nextLng")
  : "en";
// let isAuthenticated = false
// onAuthStateChanged(auth, (user:any) => {

// 	if(user){
// 		isAuthenticated = true
// 	}
// })
export const initialState = {
  user: "" || user,
  loading: true,
  isResetPassword: false,
  token: "" || token,
  errorMessage: null,
  lang: lang,
  lookups: "" || lookups,
  isAuthenticated: token ? true : false,
  brands: [],
  changingStatus: false,
  loadingInf: false,
  agency: null,
};

export const AccountReducer = (initialState: any, action: Action) => {
  switch (action.type) {
    case "CHANGE_STATUS":
      return {
        ...initialState,
        changingStatus: action.payload,
      };
    case "LOAD_INFs":
      return {
        ...initialState,
        loadingInf: action.payload,
      };
    case "CHANGE_LANG":
      return {
        ...initialState,
        lang: action.payload,
      };
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "SCREEN_LOADED":
      return {
        ...initialState,
        loading: false,
      };
    case "LOGIN_SUCCESS":
      localStorage.setItem("tokens", JSON.stringify(action.payload.tokens));
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          email: action.payload.userProfile.email,
          name: action.payload.userProfile.displayName,
        })
      );
      return {
        ...initialState,
        user: action.payload.userProfile,
        token: action.payload.tokens,
        loading: false,
        isAuthenticated: true,
      };
    case "REQUEST_LOOKUPS":
      return {
        ...initialState,
        loading: true,
        lookups: action.payload,
      };
    case "LOOKUPS_SUCCESS":
      return {
        ...initialState,
        loading: false,
        lookups: action.payload,
      };
    case "SET_USERDATA":
      return {
        ...initialState,
        user: action.payload,
      };
    case "SET_LANG":
      return {
        ...initialState,
        lang: action.payload,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
        lookups: "",
        isAuthenticated: false,
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };
    case "RESET_PASSWORD_SUCCEED":
      return {
        ...initialState,
        isResetPassword: true,
      };

    case "SET_BRANDS":
      return {
        ...initialState,
        brands: action.payload,
      };

    case "STOP_LOADING":
      return {
        ...initialState,
        loading: false,
      };

    case "AGENCY_DATA":
      return {
        ...initialState,
        agency: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
