import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { AuthStateContext } from "../../Context/MainContext";
import i18n from "../../Utils/i18n/i18n";
import { Select } from 'antd';
import { HiOutlineArrowDown, HiOutlineChevronDown } from 'react-icons/hi2';
const ChangeLanguage = ({ className }: any) => {
    const { Option } = Select;
    const { state, dispatch } = useContext(AuthStateContext);
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const handleChangeLanguage = (lng: string) => {
        changeLanguage(lng);
        dispatch({ type: "SET_LANG", payload: lng });
    };
    const changeDir = () => {
        document.body.dir = dir();
    };
    useEffect(() => {
        i18n.on("languageChanged", changeDir);

        return () => {
            // Clean up the event listener when the component unmounts
            i18n.off("languageChanged", changeDir);
        };
    }, []);

    const languageOptions = [
        { value: "en", label: `${t("english")}`, title: `${t("english")}` },
        { value: "ar", label: `${t("arabic")}`, title: `${t("arabic")}` },
    ];
    return (
        <Select
            defaultValue={state.lang || "en"}
            className={className ? className : ""}
            onChange={handleChangeLanguage}
            dropdownStyle={{width:160}}
            optionLabelProp="displayLabel"
            style={{ padding: 0,width:90 }}
            suffixIcon={<HiOutlineChevronDown className='dropdownIcon' />}
        >
            {languageOptions?.map((item: any) => (
                <Option
                    key={item.label}
                    displayLabel={item.title}
                    value={item.value}
                >
                    {item.label}
                </Option>
            ))}
        </Select>
    )
}

export default ChangeLanguage