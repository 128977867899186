import styles from "./Navbar.module.scss";
import authLogo from "../../assets/imgs/auth-lavad-logo.svg";
import ChangeLanguage from "../ChangeLanguage/Index";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { AuthStateContext } from "../../Context/MainContext";

const Navbar = () => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const { state, dispatch } = useContext(AuthStateContext);

    return (
        <div className={styles.navbar}>
            <img src={state.agency?.logoUrl || authLogo} width={125} height={32} alt="" />

            <div className={styles.navItems}>
                <div className={styles.navItemsDrops}>
                    <ChangeLanguage className={"select_lang"} />
                </div>
            </div>
        </div >
    )
}

export default Navbar;
