import api from "../../Utils/axiosInterceptor";
import { config } from "../../Utils/config";
import { lookupsMapper } from "../../Utils/helpers";



export const getLookupsEn = async () => {
    let customConfig: any = {
        headers: {
            'Language': 'en',
            'Accept-Language': 'en'
        }
    }

    const token: any = localStorage.getItem('tokens');

    // Add authorization header with the token
    if (token) {
        customConfig.headers['Authorization'] = `Bearer ${JSON.parse(token).accessToken}`;
    }
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/p/lookups`, customConfig);
    if (res.data) {

        return lookupsMapper(res.data, 'en')
    } else {
        return { error: true }
    }
}
export const getLookupsAr = async () => {
    let customConfig: any = {
        headers: {
            'Language': 'ar',
            'Accept-Language': 'ar'
        }
    }

    const token: any = localStorage.getItem('tokens');

    // Add authorization header with the token
    if (token) {
        customConfig.headers['Authorization'] = `Bearer ${JSON.parse(token).accessToken}`;
    }
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/p/lookups`, customConfig);
    if (res.data) {
        return lookupsMapper(res.data, 'ar')
    } else {
        return { error: true }
    }
}


export const getLookups = async () => {
    try {
        const resArPromise = getLookupsAr();
        const resEnPromise = getLookupsEn();

        const [resAr, resEn] = await Promise.all([resArPromise, resEnPromise]);

        return {
            en: resEn,
            ar: resAr,
        };

        // Continue with further processing or logic
    } catch (error) {
        console.log("An error occurred:", error);
    }
}
