import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

import { initReactI18next } from "react-i18next";

const apiKey = process.env.REACT_APP_I18N_NEXUS_KEY;
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
i18n.use(HttpBackend)
.use(LanguageDetector)
.use(initReactI18next).init({
  fallbackLng: "en",
  lng:localStorage.getItem("i18nextLng")||"en",
  ns: ["default"],
  defaultNS: "default",

  supportedLngs: ["en","ar"],
  
  backend: {
    loadPath: loadPath
  }
});
export default i18n;
