import Service from "../Service/Index";
import styles from "./Index.module.scss";
import { Card, Tooltip, message } from "antd";
import { useTranslation } from "react-i18next";
import { HiInformationCircle } from "react-icons/hi2";
import { formatNumber, isValidURL } from "../../../Utils/helpers";
import WarningImg from "../../../assets/imgs/Attention.svg"

const PlatformServices = ({
  lisitingAssigned = false,
  state,
  socialPlatform,
  getData,
  doUpdate,
  allAcceptedLoading,
  allRejectedLoading,
  setDisableParentActions,
}: {
  getChanged?: (data: boolean) => void;
  lisitingAssigned?: boolean;
  state: string;
  socialPlatform: any;
  infId: any;
  getData: any;
  doUpdate: any;
  allAcceptedLoading: boolean;
  allRejectedLoading: boolean;
  setDisableParentActions: any;
}) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const handleOpenMessage = () => {
    if (
      window.matchMedia("(max-width:1200px)").matches &&
      (!socialPlatform.socialPlatformUrl ||
        !isValidURL(socialPlatform.socialPlatformUrl))
    ) {
      messageApi.open({
        type: "warning",
        content: t("no_available_link"),
        className: "message",
        icon: <HiInformationCircle />,
        duration: 3,
      });
    }
  };

  const getTitle = (
    isWideScreen: boolean,
    socialPlatform: any,
    isURLValid: boolean
  ): string => {
    if (isWideScreen) {
      if (isURLValid) {
        return t("platform_profile", {
          socialPlatform: socialPlatform.name,
        });
      } else {
        return t("no_available_link");
      }
    } else {
      return "";
    }
  };
  return (
    <>
      {contextHolder}
      <Card
        className="platformCard"
        title={
          <div className={styles.platform}>
            <Tooltip
              placement={"top"}
              trigger="hover"
              destroyTooltipOnHide={true}
              overlayStyle={{
                width: "auto",
                fontFamily: "inherit",
                fontWeight: 400,
              }}
              overlayClassName="platform_tooltip"
              title={getTitle(
                window.matchMedia("(min-width:1140px)").matches,
                socialPlatform,
                isValidURL(socialPlatform.socialPlatformUrl)
              )}
            >
              <a
                href={
                  socialPlatform.socialPlatformUrl &&
                    isValidURL(socialPlatform.socialPlatformUrl)
                    ? socialPlatform.socialPlatformUrl
                    : undefined
                }
                rel="noreferrer"
                target="_blank"
                onClick={handleOpenMessage}
              >
                <img
                  loading="lazy"
                  src={socialPlatform.logoUrl}
                  alt="platform"
                />
              </a>
            </Tooltip>
            <div>
              <p className={styles.socialPlatformName}>
                {socialPlatform.name}

                {socialPlatform.followersCount != 0 &&
                  socialPlatform.followersCount > 0 && (
                    <span className={styles.followersCount}>
                      ({formatNumber(socialPlatform.followersCount)}{" "}
                      {t("followers")})
                    </span>
                  )}
              </p>

              {/* {!socialPlatform.isActive ? <div className={styles.warning}>
                <img src={WarningImg} />
                <p>{t('deactivated_sm_warning')}</p>
              </div> : ""} */}
            </div>

          </div>
        }
      >
        {socialPlatform.services.map((s: any) => (
          <Card.Grid className={`cardGrid ${s.bookingStatusKey.toLowerCase() === 'rejected' ? 'dimmed' : ''}`} key={s.name} hoverable={false}>
            <Service
              data={s}
              lisitingAssigned={lisitingAssigned}
              key={s.advertisingProductId}
              name={s.name}
              state={state}
              currencyKey={s.currencyKey}
              getData={getData}
              doUpdate={doUpdate}
              allAcceptedLoading={allAcceptedLoading}
              allRejectedLoading={allRejectedLoading}
              setDisableParentActions={setDisableParentActions}
            />
          </Card.Grid>
        ))}
      </Card>
    </>
  );
};

export default PlatformServices;
