import styles from "./Index.module.scss";
import { useState, useEffect, useContext } from "react";
import {
  HiOutlineCheck,
  HiOutlineCheckCircle,
  HiOutlineXCircle,
} from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { AuthStateContext } from "../../../Context/MainContext";
import { HiOutlineX } from "react-icons/hi";
import { changeLineItemStatus } from "../../../Context/Actions/campaign";
import { notification } from "antd";
import { BiLoaderAlt } from "react-icons/bi";
import i18n from "../../../Utils/i18n/i18n";

const Service = ({
  getChanged,
  lisitingAssigned = false,
  isDetailsBox = false,
  state,
  price = 0,
  currencyKey,
  name,
  data,
  getData,
  doUpdate,
  allAcceptedLoading,
  allRejectedLoading,
  setDisableParentActions,
  platformKey,
  influencerId,
  serviceKey,
  basePrice,
}: {
  lisitingAssigned?: boolean;
  isDetailsBox?: boolean;
  state: string;
  price?: any;
  currencyKey: any;
  name: string;
  getChanged?: (data: boolean) => void;
  data: any;
  getData?: any;
  doUpdate?: any;
  allAcceptedLoading?: boolean;
  allRejectedLoading?: boolean;
  setDisableParentActions?: any;
  platformKey?: any;
  influencerId?: any;
  serviceKey?: any;
  basePrice?: any;
}) => {
  const [localPrice, setLocalPrice] = useState("");
  const [bookingLineItemStatus, setBookingLineItemStatus] = useState<any>([]);
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const [serviceAcceptLoading, setServiceAcceptLoading] =
    useState<boolean>(false);
  const [serviceRejectLoading, setServiceRejectLoading] =
    useState<boolean>(false);
  const {
    state: { lookups, changingStatus, loadingInf },
    dispatch
  } = useContext(AuthStateContext);

  useEffect(() => {
    if (data.proposedPrice) {
      setLocalPrice(data.proposedPrice);
    }
    if (state === "actions") {
      setLocalPrice(data.price);
    }
    if (price > 0) {
      setLocalPrice(price);
    }
    setServiceAcceptLoading(false);
    setServiceRejectLoading(false);
  }, [data]);

  useEffect(() => {
    if (!loadingInf) {
      if (serviceAcceptLoading)
        setServiceAcceptLoading(false)
      if (setServiceRejectLoading)
        setServiceRejectLoading(false);
    }
  }, [loadingInf])

  useEffect(() => {
    let lineItemStatus: any = [];
    lookups[i18n.language]?.lineItemBookingStatuses.map((status: any) => {
      lineItemStatus.push({
        label: status.name,
        key: status.key,
      });
    });
    setBookingLineItemStatus(lineItemStatus);
  }, [lookups]);

  const changeStatus = (status: string) => {
    setDisableParentActions(true)
    status === "Accepted"
      ? setServiceAcceptLoading(true)
      : setServiceRejectLoading(true);
    changeLineItemStatus(data.lineItemId, status).then((res) => {
      if (!res.error) {
        data.bookingStatusKey = status;
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("success_label"),
          description: t("service_status_changed"),
          icon: <HiOutlineCheckCircle className="success-text" />,
        });
        getData(true);
        doUpdate(true);


      }
      if (res.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
        status === "Accepted"
          ? setServiceAcceptLoading(false)
          : setServiceRejectLoading(false);


      }
      setDisableParentActions(false)
    });
  };
  const checkStatus = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <div className={styles.btn_wrapper}>
            <button
              onClick={() => changeStatus("Rejected")}
              className={`lavad-btn lavad-btn-danger-outline ${styles.status_btn} ${allAcceptedLoading || allRejectedLoading || serviceRejectLoading || serviceAcceptLoading ? 'disabled' : ''} ${allAcceptedLoading || allRejectedLoading ? styles.disablePointers : ""}`}
            >
              {serviceRejectLoading ? (
                <span className="icon-loader spin"></span>
              ) : (
                <HiOutlineX className="danger-text" />
              )}
            </button>
            <button
              onClick={() => changeStatus("Accepted")}
              className={`lavad-btn lavad-btn-success-outline ${styles.status_btn} ${allAcceptedLoading || allRejectedLoading || serviceRejectLoading || serviceAcceptLoading ? 'disabled' : ''} ${allAcceptedLoading || allRejectedLoading ? styles.disablePointers : ""}`}
            >
              {serviceAcceptLoading ? (
                <span className="icon-loader spin"></span>
              ) : (
                <HiOutlineCheck className="success-text" />
              )}
            </button>
          </div>
        );
      case "accepted":
        return (
          <span className={styles.status_text}>
            <HiOutlineCheck className="success-text" />{" "}
            {
              bookingLineItemStatus?.find(
                (s: any) => s.key == data.bookingStatusKey
              )?.label
            }
          </span>
        );
      case "rejected":
        return (
          <span className={styles.status_text}>
            <HiOutlineX className="danger-text" />{" "}
            {
              bookingLineItemStatus?.find(
                (s: any) => s.key === data.bookingStatusKey
              )?.label
            }
          </span>
        );
        break;

      default:
        break;
    }
  };
  return (
    <div
      className={`${styles.service_container} ${isDetailsBox ? styles.details_container : ""
        } ${lisitingAssigned ? styles.assigned_container : ""}`}
    >
      <div
        className={`${styles.service} ${state == "view" ? styles.view : ""} ${isDetailsBox ? styles.details : ""
          }`}
      >
        <p className='body _lr-hide' data-private>{name}</p>
        {/* {((state == "view") && localPrice && Number(localPrice) > 0) ? <p className={`${styles.price} body`}>{new Intl.NumberFormat().format(+localPrice)}  <span className={styles.curr}>{t(`${currencyKey}`)}</span></p> : ""} */}

        {state == "actions" && localPrice ? (
          <p className={`${styles.price} body _lr-hide`} data-private>
            {new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
            }).format(+localPrice)}{" "}
            <span>{t(`${currencyKey}`)}</span>
          </p>
        ) : (
          ""
        )}
      </div>
      {state == "actions" && <div className={styles.status_wrapper}>
        {checkStatus(data.bookingStatusKey.toLowerCase())}
      </div>}
    </div>
  );
};

export default Service;
