import { useTranslation } from "react-i18next";
import i18n from "./i18n/i18n";
import { t } from "i18next";
import countries from "../countryIsoTwoCode.json";
import getPalette from "tailwindcss-palette-generator";

const XLSX = require("xlsx-js-style");

const getFontSize = (size: number) => {
  return size * 0.5;
};

export const formatNumber = (num: any): string => {
  const absNum = Math.abs(num);
  if (absNum >= 1e9) {
    return (
      Number(
        Number.isInteger(num / 1e9)
          ? (num / 1e9).toFixed(0)
          : (num / 1e9).toFixed(1)
      ) + "B"
    );
  } else if (absNum >= 1e6) {
    return (
      Number(
        Number.isInteger(num / 1e6)
          ? (num / 1e6).toFixed(0)
          : (num / 1e6).toFixed(1)
      ) + "M"
    );
  } else if (absNum >= 1e3) {
    return (
      Number(
        Number.isInteger(num / 1e3)
          ? (num / 1e3).toFixed(0)
          : (num / 1e3).toFixed(1)
      ) + "K"
    );
  } else {
    return num.toFixed(0).toString();
  }
};

export const moneyFormatter = (price: any) => {
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
    +price
  );
};

export const isValidURL = (url: string) => {
  const pattern = new RegExp(
    /^(https?:\/\/)?([\w.-]+)(\.[\w.-]+)+([/?#][^\s]*)?$/i
  );
  return pattern.test(url);
};

const getMinMax = (data: any) => {
  let prices: any = [];
  data.socialPlatforms?.forEach((plat: any) => {
    plat.services?.forEach((ser: any) => {
      prices.push(ser.basePrice);
    });
  });
  return { min: Math.min(...prices), max: Math.max(...prices) };
};

export const influencerMappedData = async (
  state: any,
  data: any,
  lang: string = i18n.language
) => {
  let minPrice = Number.MAX_VALUE;
  let maxPrice = Number.MIN_VALUE;
  data.influencerSocialPlatforms = data.socialPlatforms;
  data.influencerBrandSafetyFlagKeys = data.metaData?.brandSafetyFlagKeys;
  data.influencerQualityKey = data.metaData?.qualityKey;
  data.influencerNicheKeys = data.metaData?.nicheKeys;
  data.isFeatured = data.metaData?.isFeatured;
  data.influencerTags = data.metaData?.tagKeys;
  data.influencerIndustries = data.metaData?.industryKeys;

  data = {
    ...data,
    ...data.basicInfo,
    speakingLanguages: data.basicInfo?.speakingLanguageKeys?.map(
      (key: any) =>
        state?.lookups[lang]?.speakingLanguages?.find((l: any) => l.key == key)
          .name
    ),
    maritalStatus: state?.lookups[lang]?.maritalStatuses?.find(
      (l: any) => l.key == data.basicInfo?.maritalStatusKey
    )?.name,
    nationality: state?.lookups[lang]?.countries?.find(
      (l: any) => l.key == data.basicInfo?.nationalityCountryKey
    )?.nationality,
    gender: state?.lookups[lang]?.genders?.find(
      (l: any) => l.key == data.basicInfo?.genderKey
    )?.name,
    relationships: data.relationships?.map((r: any) => {
      return {
        ...r,
        typeKey: state?.lookups[lang]?.relationshipTypes?.find(
          (rel: any) => rel.key == r.typeKey
        )?.name,
      };
    }),
    ...data.exclusiveBrands,
    exclusiveBrands: data.exclusiveBrands?.map((b: any) => ({
      ...b,
      brandInfo: {
        ...b.brandInfo,
        industryKeys: b.brandInfo.industryKeys?.map(
          (i: any) =>
            state?.lookups[lang]?.industries?.find((ind: any) => ind.key == i)
              ?.name
        ),
      },
    })),
    recommended: data.metaData?.isRecommended || data.recommended,
  };
  if (data.basicInfo?.ageRange) {
    data.ageRange = state?.lookups[lang].ageRanges.find(
      (k: any) => k.key == data.basicInfo?.ageRange
    ).name;
  }
  const country = state?.lookups[lang]?.countries?.find(
    (country: any) => country.key == data.basicInfo?.residencyInfo?.countryKey
  );
  const city = country?.cities?.find(
    (c: any) => c.key == data.basicInfo?.residencyInfo?.cityKey
  );
  data.residency = city && country ? `${city.name}, ${country.name}` : "";

  await state.lookups[lang].socialPlatforms.forEach((lookupItem: any) => {
    data.socialPlatforms?.forEach((InfluencerItem: any) => {
      if (InfluencerItem.key === lookupItem.key) {
        InfluencerItem.logoUrl = lookupItem.logoUrl;
        InfluencerItem.name = lookupItem.name;
        if (InfluencerItem.influencerTypeKey) {
          InfluencerItem.influencerType = state?.lookups[
            lang
          ]?.influencerTypes.find(
            (k: any) => k.key == InfluencerItem.influencerTypeKey
          ).name;
        }
        lookupItem.socialPlatformServices.forEach((lookupService: any) => {
          InfluencerItem?.services?.forEach((influencerService: any) => {
            if (lookupService.key === influencerService.key) {
              influencerService.name = lookupService.name;
            }
            if (influencerService.lineItemId) {
              influencerService.selected = true;
            }
            if (influencerService.basePrice) {
              console.log("basePrice");
              const basePrice = parseFloat(
                influencerService.basePrice
                  ? influencerService.basePrice
                  : influencerService.price
              );
              if (basePrice < minPrice) {
                minPrice = basePrice;
              }
              if (basePrice > maxPrice) {
                maxPrice = basePrice;
              }
            }
          });
        });
      }
    });
  });
  const { min, max } = getMinMax(data);
  console.log(`min: ${min}, max: ${max}`);
  await state.lookups[lang].socialPlatforms.forEach((lookupItem: any) => {
    data.influencerSocialPlatforms?.forEach((InfluencerItem: any) => {
      if (InfluencerItem.audienceSegmentation?.ageRanges) {
        InfluencerItem.audienceSegmentation.ageRanges["from"] = state.lookups[
          lang
        ].ageRanges.find(
          (g: any) =>
            g.key === InfluencerItem.audienceSegmentation?.ageRanges.key
        ).from;
        InfluencerItem.audienceSegmentation.ageRanges["to"] = state.lookups[
          lang
        ].ageRanges.find(
          (g: any) =>
            g.key === InfluencerItem.audienceSegmentation?.ageRanges.key
        ).to;
      }
      if (InfluencerItem.key === lookupItem.key) {
        InfluencerItem.logoUrl = lookupItem.logoUrl;
        InfluencerItem.name = lookupItem.name;
      }
    });
  });
  data.influencerSocialPlatforms.maxBasePrice =
    data.metaData?.avgServicePrice?.to >= 0
      ? data.metaData?.avgServicePrice?.to
      : max;
  data.influencerSocialPlatforms.minBasePrice =
    data.metaData?.avgServicePrice?.from >= 0
      ? data.metaData?.avgServicePrice?.from
      : min;
  let foundCountry: any = null;
  await data.influencerSocialPlatforms?.forEach((InfluencerItem: any) => {
    if (InfluencerItem.audienceSegmentation?.ageRanges) {
      InfluencerItem.audienceSegmentation.ageRanges["from"] = state.lookups[
        lang
      ].ageRanges.find(
        (g: any) => g.key === InfluencerItem.audienceSegmentation?.ageRanges.key
      ).from;
      InfluencerItem.audienceSegmentation.ageRanges["to"] = state.lookups[
        lang
      ].ageRanges.find(
        (g: any) => g.key === InfluencerItem.audienceSegmentation?.ageRanges.key
      ).to;
    }
    InfluencerItem.audienceSegmentation?.countries?.map((country: any) => {
      foundCountry = state.lookups[lang].countries.find(
        (c: any) => c.key === country.key
      );
      country.name = foundCountry.name;
      country.isoTwoCode = foundCountry.isoTwoCode;
    });
    InfluencerItem.audienceSegmentation?.genders?.sort((g1: any, g2: any) =>
      g2.key > g1.key ? -1 : g2.key < g1.key ? 1 : 0
    );

    InfluencerItem.audienceSegmentation?.genders?.map(
      (gender: any) =>
        (gender.name = state.lookups[lang].genders.find(
          (g: any) => g.key === gender.key
        ).name)
    );

    if (InfluencerItem.audienceSegmentation?.otherSegmentsKeys) {
      let otherSegments: any = [];
      InfluencerItem.audienceSegmentation?.otherSegmentsKeys?.map(
        (other: any) =>
          otherSegments.push(
            state.lookups[lang].audienceSegments.find(
              (x: any) => x.key == other
            ).name
          )
      );
      InfluencerItem.audienceSegmentation.otherSegments = otherSegments;
    }
  });

  await state.lookups[lang].niches?.forEach((lookupNiche: any) => {
    data.metaData?.nicheKeys?.forEach((influencerNiche: any, index: number) => {
      if (lookupNiche.key === influencerNiche) {
        data.influencerNicheKeys[index] = {
          key: influencerNiche,
          name: lookupNiche.name,
        };
      }
    });
  });

  await state.lookups[lang].tags?.forEach((lookupTag: any) => {
    data.metaData?.tagKeys?.forEach((influencerTag: any, index: number) => {
      if (lookupTag.key === influencerTag) {
        data.influencerTags[index] = {
          key: influencerTag,
          name: lookupTag.name,
        };
      }
    });
  });

  await state.lookups[lang].industries?.forEach((lookupIndustry: any) => {
    data.metaData?.industryKeys?.forEach(
      (influencerIndustry: any, index: number) => {
        if (lookupIndustry.key === influencerIndustry) {
          data.influencerIndustries[index] = {
            key: influencerIndustry,
            name: lookupIndustry.name,
          };
        }
      }
    );
  });

  await state.lookups[lang].commitmentLevels?.forEach((level: any) => {
    if (
      data.metaData?.commitmentLevelKey &&
      data.metaData?.commitmentLevelKey == level.key
    ) {
      data.metaData.commitmentLevel = {
        key: level.key,
        name: level.name,
      };
    }
  });

  await state.lookups[lang].brandSafetyFlags?.forEach(
    (lookupBrandSaftey: any) => {
      data.influencerBrandSafetyFlagKeys?.forEach(
        (influencerBrandSaftey: any, index: number) => {
          if (lookupBrandSaftey.key === influencerBrandSaftey) {
            data.influencerBrandSafetyFlagKeys[index] = {
              key: influencerBrandSaftey,
              name: lookupBrandSaftey.name,
            };
          }
        }
      );
    }
  );
  await state.lookups[lang].influencerQualities?.forEach(
    (lookupInfluencerQuality: any) => {
      if (data.influencerQualityKey === lookupInfluencerQuality.key) {
        data.influencerQualityKey = lookupInfluencerQuality;
      }
    }
  );

  return data;
};
export const influencerMappedDataPPT = async (
  state: any,
  data: any,
  lang: string = i18n.language
) => {
  let minPrice = Number.MAX_VALUE;
  let maxPrice = Number.MIN_VALUE;
  data.influencerSocialPlatforms = data.socialPlatforms;
  data.influencerBrandSafetyFlagKeys = data.metaData?.brandSafetyFlagKeys;
  data.influencerQualityKey = data.metaData?.qualityKey;
  data.influencerNicheKeys = data.metaData?.nicheKeys;
  data.isFeatured = data.metaData?.isFeatured;
  data.influencerTags = data.metaData?.tagKeys;
  data = {
    ...data,
    ...data.basicInfo,
    speakingLanguages: data.basicInfo?.speakingLanguageKeys?.map(
      (key: any) =>
        state?.lookups[lang]?.speakingLanguages?.find((l: any) => l.key == key)
          .name
    ),
    maritalStatus: state?.lookups[lang]?.maritalStatuses?.find(
      (l: any) => l.key == data.basicInfo?.maritalStatusKey
    )?.name,
    nationality: state?.lookups[lang]?.countries?.find(
      (l: any) => l.key == data.basicInfo?.nationalityCountryKey
    )?.nationality,
    gender: state?.lookups[lang]?.genders?.find(
      (l: any) => l.key == data.basicInfo?.genderKey
    )?.name,
    relationships: data.relationships?.map((r: any) => {
      return {
        ...r,
        typeKey: state?.lookups[lang]?.relationshipTypes?.find(
          (rel: any) => rel.key == r.typeKey
        )?.name,
      };
    }),
    ...data.exclusiveBrands,
    exclusiveBrands: data.exclusiveBrands?.map((b: any) => ({
      ...b,
      brandInfo: {
        ...b.brandInfo,
        industryKeys: b.brandInfo.industryKeys?.map(
          (i: any) =>
            state?.lookups[lang]?.industries?.find((ind: any) => ind.key == i)
              ?.name
        ),
      },
    })),
  };
  const country = state?.lookups[lang]?.countries?.find(
    (country: any) => country.key == data.basicInfo?.residencyInfo?.countryKey
  );
  const city = country?.cities?.find(
    (c: any) => c.key == data.basicInfo?.residencyInfo?.cityKey
  );
  data.residency = city && country ? `${city.name}, ${country.name}` : "-";
  await state.lookups[lang].socialPlatforms.forEach((lookupItem: any) => {
    data.influencerSocialPlatforms?.forEach((InfluencerItem: any) => {
      if (InfluencerItem.audienceSegmentation?.ageRanges) {
        InfluencerItem.audienceSegmentation.ageRanges["from"] = state.lookups[
          lang
        ].ageRanges.find(
          (g: any) =>
            g.key === InfluencerItem.audienceSegmentation?.ageRanges.key
        ).from;
        InfluencerItem.audienceSegmentation.ageRanges["to"] = state.lookups[
          lang
        ].ageRanges.find(
          (g: any) =>
            g.key === InfluencerItem.audienceSegmentation?.ageRanges.key
        ).to;
      }
      if (InfluencerItem.key === lookupItem.key) {
        InfluencerItem.logoUrl = lookupItem.logoUrl;
        InfluencerItem.name = lookupItem.name;
        // lookupItem.socialPlatformServices.forEach((lookupService: any) => {
        //   InfluencerItem.services.forEach((influencerService: any) => {
        //     if (lookupService.key === influencerService.key) {
        //       influencerService.name = lookupService.name;
        //     }
        //     if (influencerService.lineItemId) {
        //       influencerService.selected = true;
        //     }
        //     const basePrice = parseFloat(
        //       influencerService.basePrice
        //         ? influencerService.basePrice
        //         : influencerService.price
        //     );
        //     if (basePrice < minPrice) {
        //       minPrice = basePrice;
        //     }
        //     if (basePrice > maxPrice) {
        //       maxPrice = basePrice;
        //     }
        //   });
        // });
      }
    });
  });
  data.influencerSocialPlatforms.maxBasePrice =
    data.metaData?.avgServicePrice?.to;
  data.influencerSocialPlatforms.minBasePrice =
    data.metaData?.avgServicePrice?.from;

  await state.lookups[lang].niches?.forEach((lookupNiche: any) => {
    data.influencerNicheKeys?.forEach((influencerNiche: any, index: number) => {
      if (lookupNiche.key === influencerNiche.key) {
        data.influencerNicheKeys[index] = {
          key: influencerNiche.key,
          name: lookupNiche.name,
        };
      }
    });
  });

  await state.lookups[lang].tags?.forEach((lookupTag: any) => {
    data.metaData?.tagKeys?.forEach((influencerTag: any, index: number) => {
      if (lookupTag.key === influencerTag) {
        data.influencerTags[index] = {
          key: influencerTag,
          name: lookupTag.name,
        };
      }
    });
  });

  await state.lookups[lang].commitmentLevels?.forEach((level: any) => {
    if (
      data.metaData?.commitmentLevelKey &&
      data.metaData?.commitmentLevelKey == level.key
    ) {
      data.metaData.commitmentLevel = {
        key: level.key,
        name: level.name,
      };
    }
  });
  await state.lookups[lang].brandSafetyFlags?.forEach(
    (lookupBrandSaftey: any) => {
      data.influencerBrandSafetyFlagKeys?.forEach(
        (influencerBrandSaftey: any, index: number) => {
          if (lookupBrandSaftey.key === influencerBrandSaftey.key) {
            data.influencerBrandSafetyFlagKeys[index] = {
              key: influencerBrandSaftey.key,
              name: lookupBrandSaftey.name,
            };
          }
        }
      );
    }
  );

  await state.lookups[lang].influencerQualities?.forEach(
    (lookupInfluencerQuality: any) => {
      if (data.influencerQualityKey.key === lookupInfluencerQuality.key) {
        data.influencerQualityKey = lookupInfluencerQuality;
      }
    }
  );

  return data;
};

const pixelsToInches = (pixels: number) => {
  return pixels / 144;
};

const pixelsToPoints = (pixels: number) => {
  return pixels * 0.8;
};

const rtl = (x: number, w: number) => 13.333 - x - w;
const rtlItems = (
  x: number,
  cardW: number,
  elementWidth: number,
  gutter: number
) => x + (cardW - elementWidth - gutter);

const sortInfluencersByPlatformCountDescending = (influencers: any) => {
  return influencers;
};

const getCommitmentImgAndName = (key: string, lang: string) => {
  if (key == "High") {
    return {
      name: lang == "en" ? "High Commitment" : "التزام عال",
      img: `${process.env.PUBLIC_URL}/imgs/commited-high.svg`,
    };
  } else if (key == "Moderate") {
    return {
      name: lang == "en" ? "Moderate Commitment" : "التزام متوسط",
      img: `${process.env.PUBLIC_URL}/imgs/commited-mid.svg`,
    };
  } else {
    return {
      name: lang == "en" ? "Low Commitment" : "التزام منخفض",
      img: `${process.env.PUBLIC_URL}/imgs/commited-low.svg`,
    };
  }
};

export const generatePowerPointSlides = (
  pptx: any,
  influencers: any,
  details: any,
  lang: any,
  withPrices: boolean = true,
  downloading: any,
  agencyDetails: any
) => {
  downloading("loading");
  let slide: any;
  let numOfFullHiehgtCards: any = 0;
  let slideSize: any = 0;
  let outerSpaceX = pixelsToInches(0);
  let outerSpaceY = pixelsToInches(0);
  let lastInfIndex = 0;
  pptx.layout = "LAYOUT_WIDE";
  generatePPtCover(lang, details, pptx, influencers, agencyDetails);
  lang === "ar" ? (pptx.rtlMode = true) : (pptx.rtlMode = false);
  slide = pptx.addSlide();

  let sortedInfs = sortInfluencersByPlatformCountDescending(influencers);
  sortedInfs.forEach((influencer: any, index: any) => {
    lastInfIndex = index;
    const { influencerSocialPlatforms } = influencer;
    // Calculate the number of cards based on the number of social platforms
    const numPlatforms = influencerSocialPlatforms.length;

    const isFullHeightCard = true;

    // const isFullHeightCard = numPlatforms > 3;
    if (isFullHeightCard) {
      slideSize = slideSize + 2;
      numOfFullHiehgtCards = numOfFullHiehgtCards + 1;
    } else {
      slideSize = slideSize + 1;
    }
    // calculate locations
    let cardsGap = pixelsToInches(0);
    let cardWidth = pixelsToInches(960);
    let cardFullHeight = pixelsToInches(684);
    let cardHalfHeight = pixelsToInches(334);

    let cardStartX =
      lang == "en"
        ? slideSize > 2
          ? outerSpaceX + cardsGap + cardWidth
          : outerSpaceX
        : slideSize <= 2
        ? outerSpaceX + cardsGap + cardWidth
        : outerSpaceX;
    let cardStartY =
      (numOfFullHiehgtCards === 0 && (slideSize === 4 || slideSize === 2)) ||
      (numOfFullHiehgtCards === 1 && slideSize === 4)
        ? cardHalfHeight + cardsGap + outerSpaceY
        : outerSpaceY;

    platformServices(
      cardStartX,
      cardStartY,
      influencer,
      slide,
      pptx,
      withPrices,
      cardWidth,
      lang
    );
    generateCardHeader(
      sortedInfs.length,
      index,
      cardStartX,
      cardStartY,
      influencer,
      details,
      pptx,
      slide,
      lang,
      cardStartX,
      cardWidth
    );
    // Create a new slide if necessary

    if (slideSize === 4) {
      if (lastInfIndex + 1 < sortedInfs.length) {
        slide = pptx.addSlide();
        slideSize = 0;
        numOfFullHiehgtCards = 0;
      }
    }
  });
  if (sortedInfs.length % 2 != 0) {
    slide.addShape(pptx.shapes.RECTANGLE, {
      x: lang == "en" ? pixelsToInches(960) : 0,
      y: 0,
      w: pixelsToInches(960),
      h: pixelsToInches(1080),
      fill: "fafafa", // fill color
    });
  }

  // Save the PowerPoint file
  pptx
    .writeFile({ fileName: `Influencers Plan - ${details.name}.pptx` })
    .then((fileName: string) => {
      downloading("loaded");
    })
    .catch((error: any) => {
      downloading("invalid");
    });
};

const platformServices = (
  startX: number,
  startY: number,
  influencerData: any,
  slide: any,
  pptx: any,
  withPrices: boolean,
  cardWidth: number,
  lang: string
) => {
  let startPointX = startX + pixelsToInches(32);

  let startPointY = startY + pixelsToInches(286);
  let textTitleWidth = pixelsToInches(145);
  let containerWidth = pixelsToInches(415);
  let gutter = pixelsToInches(16);

  influencerData.influencerSocialPlatforms.forEach((item: any, index: any) => {
    platformLine(
      startPointX,
      startPointY,
      index,
      item,
      slide,
      pptx,
      withPrices,
      cardWidth,
      lang,
      containerWidth
    );
  });
};

const platformLine = (
  startX: number,
  startY: number,
  itemIndex: number,
  item: any,
  slide: any,
  pptx: any,
  withPrices: boolean,
  cardWidth: number,
  lang: string,
  containerWidth: number
) => {
  let audienceSegmentation: any = [];

  audienceSegmentation.push(
    {
      items:
        item.audienceSegmentation?.countries?.length == 2
          ? item.audienceSegmentation?.countries
          : item.audienceSegmentation?.countries?.length == 1
          ? [...item.audienceSegmentation?.countries, false]
          : [false, false],
      key: "countries",
    },
    {
      items:
        item.audienceSegmentation?.genders?.length == 2
          ? item.audienceSegmentation?.genders
          : item.audienceSegmentation?.genders?.length == 1
          ? [
              ...item.audienceSegmentation?.genders,
              item.audienceSegmentation?.genders.find(
                (k: any) => k.key.toLowerCase() == "male"
              )
                ? "female"
                : "male",
            ].sort((a: any, b: any) =>
              a?.key?.toLowerCase() == "female" || a == "female"
                ? -1
                : a?.key?.toLowerCase() == "male" || a == "male"
                ? 1
                : 0
            )
          : ["female", "male"],
      key: "genders",
    },
    { items: item.audienceSegmentation?.ageRanges || {}, key: "ageRanges" }
  );

  slide.addShape(pptx.shapes.RECTANGLE, {
    x:
      lang == "en"
        ? startX
        : rtlItems(startX, cardWidth, pixelsToInches(896), pixelsToInches(64)),
    y:
      pixelsToInches(24 * (itemIndex + 1)) +
      startY +
      itemIndex * pixelsToInches(162),
    w: lang == "en" ? pixelsToInches(894) : pixelsToInches(896),
    h: pixelsToInches(162),
    rectRadius: [0.1],
    fill: "fafafa", // fill color
    line: "eeeeee", // border color
    lineSize: 1, // border thickness
  });

  let gutter = pixelsToInches(66.5);
  let socialIconWidth = pixelsToInches(48);
  let socialIconHeight = pixelsToInches(48);
  let imgStartX =
    lang == "en"
      ? startX + pixelsToInches(66.5)
      : rtlItems(
          startX - pixelsToInches(66.5),
          cardWidth,
          socialIconWidth,
          gutter
        );
  let imgStartY =
    pixelsToInches(24 * (itemIndex + 1)) +
    startY +
    itemIndex * pixelsToInches(162) +
    pixelsToInches(26.5);

  slide.addImage({
    path: `${process.env.PUBLIC_URL}/imgs/${
      item.key.toLowerCase() + "_square.png"
    }`,
    x: imgStartX,
    y: imgStartY,
    w: socialIconWidth,
    h: socialIconHeight,
    ...(item.socialPlatformUrl && isValidURL(item.socialPlatformUrl)
      ? {
          hyperlink: {
            url: item.socialPlatformUrl,
          },
        }
      : {}),
  });

  // platforms container

  slide.addShape(pptx.shapes.RECTANGLE, {
    x:
      lang == "en"
        ? startX + pixelsToInches(179)
        : rtlItems(
            startX - pixelsToInches(179),
            cardWidth,
            pixelsToInches(714.5),
            gutter
          ),
    y:
      pixelsToInches(24 * (itemIndex + 1)) +
      startY +
      itemIndex * pixelsToInches(162),
    w: pixelsToInches(714.5),
    h: pixelsToInches(98),
    rectRadius: [0.1],
    fill: "ffffff", // fill color
    line: "eeeeee", // border color
    lineSize: 1, // border thickness
  });

  // draw segements

  if (audienceSegmentation.length) {
    let x = 16;
    for (let item of audienceSegmentation) {
      // {items:item.audienceSegmentation.countries,key:"countries"},
      // {items:item.audienceSegmentation.genders,key:"genders"},
      // {items:item.audienceSegmentation.ageRanges,key:"ageRanges"}
      if (item?.items) {
        if (item.key == "countries") {
          let j = 0;
          item.items.forEach((i: any, index: any) => {
            if (i) {
              if (countries.find((a) => a == i.isoTwoCode.toLowerCase())) {
                slide.addImage({
                  path: `${
                    process.env.PUBLIC_URL
                  }/imgs/${i.isoTwoCode.toLowerCase()}.svg`,
                  x:
                    lang == "en"
                      ? startX +
                        pixelsToInches(179) +
                        pixelsToInches(16) +
                        pixelsToInches(j)
                      : rtlItems(
                          startX -
                            pixelsToInches(179) -
                            pixelsToInches(16) -
                            pixelsToInches(j),
                          cardWidth,
                          pixelsToInches(32),
                          gutter
                        ),
                  y:
                    pixelsToInches(24 * (itemIndex + 1)) +
                    startY +
                    pixelsToInches(114) +
                    itemIndex * pixelsToInches(162),
                  w: pixelsToInches(32),
                  h: pixelsToInches(32),
                  rounding: true,
                  sizing: {
                    type: "cover",
                    w: pixelsToInches(32),
                    h: pixelsToInches(32),
                  },
                });
                slide.addText(`${Math.round(i.percentage)}%`, {
                  x:
                    lang == "en"
                      ? startX +
                        pixelsToInches(179) +
                        pixelsToInches(16) +
                        pixelsToInches(j) +
                        pixelsToInches(24)
                      : rtlItems(
                          startX -
                            pixelsToInches(179) -
                            pixelsToInches(16) -
                            pixelsToInches(j) -
                            pixelsToInches(24),
                          cardWidth,
                          pixelsToInches(65),
                          gutter
                        ),
                  y:
                    pixelsToInches(24 * (itemIndex + 1)) +
                    startY +
                    pixelsToInches(120) +
                    itemIndex * pixelsToInches(162),
                  w: pixelsToInches(65),
                  h: pixelsToInches(18),
                  fontSize: getFontSize(16),
                  bold: true,
                  align: lang == "en" ? "left" : "right",
                  fontFace: lang == "en" ? "Arial" : "Segoe UI",
                });
                //
                if (index == 0 && item.items?.length > 1) {
                  slide.addShape(pptx.ShapeType.line, {
                    x:
                      lang == "en"
                        ? startX +
                          pixelsToInches(179) +
                          pixelsToInches(16) +
                          pixelsToInches(j) +
                          pixelsToInches(80)
                        : rtlItems(
                            startX -
                              pixelsToInches(179) -
                              pixelsToInches(16) -
                              pixelsToInches(j) -
                              pixelsToInches(80),
                            cardWidth,
                            0,
                            gutter
                          ), // X-coordinate
                    y:
                      pixelsToInches(24 * (itemIndex + 1)) +
                      startY +
                      pixelsToInches(115) +
                      itemIndex * pixelsToInches(162), // Start Y-coordinate
                    w: 0,
                    h: pixelsToInches(32), // Height (the difference between start and end Y-coordinates)
                    line: { color: "eeeeee", width: 1 }, // Line color and width
                  });
                }
              }
            } else {
              slide.addImage({
                path: `${process.env.PUBLIC_URL}/imgs/globe-ppt.svg`,
                x:
                  lang == "en"
                    ? startX +
                      pixelsToInches(179) +
                      pixelsToInches(16) +
                      pixelsToInches(j)
                    : rtlItems(
                        startX -
                          pixelsToInches(179) -
                          pixelsToInches(16) -
                          pixelsToInches(j),
                        cardWidth,
                        pixelsToInches(32),
                        gutter
                      ),
                y:
                  pixelsToInches(24 * (itemIndex + 1)) +
                  startY +
                  pixelsToInches(114) +
                  itemIndex * pixelsToInches(162),
                w: pixelsToInches(32),
                h: pixelsToInches(32),
                rounding: true,
                sizing: {
                  type: "cover",
                  w: pixelsToInches(32),
                  h: pixelsToInches(32),
                },
              });
              slide.addText(`-`, {
                x:
                  lang == "en"
                    ? startX +
                      pixelsToInches(179) +
                      pixelsToInches(16) +
                      pixelsToInches(j) +
                      pixelsToInches(24)
                    : rtlItems(
                        startX -
                          pixelsToInches(179) -
                          pixelsToInches(16) -
                          pixelsToInches(j) -
                          pixelsToInches(24),
                        cardWidth,
                        pixelsToInches(65),
                        gutter
                      ),
                y:
                  pixelsToInches(24 * (itemIndex + 1)) +
                  startY +
                  pixelsToInches(120) +
                  itemIndex * pixelsToInches(162),
                w: pixelsToInches(65),
                h: pixelsToInches(18),
                fontSize: getFontSize(16),
                bold: true,
                align: lang == "en" ? "left" : "right",
                fontFace: lang == "en" ? "Arial" : "Segoe UI",
              });
              //
              if (index == 0 && item.items?.length > 1) {
                slide.addShape(pptx.ShapeType.line, {
                  x:
                    lang == "en"
                      ? startX +
                        pixelsToInches(179) +
                        pixelsToInches(16) +
                        pixelsToInches(j) +
                        pixelsToInches(80)
                      : rtlItems(
                          startX -
                            pixelsToInches(179) -
                            pixelsToInches(16) -
                            pixelsToInches(j) -
                            pixelsToInches(80),
                          cardWidth,
                          0,
                          gutter
                        ), // X-coordinate
                  y:
                    pixelsToInches(24 * (itemIndex + 1)) +
                    startY +
                    pixelsToInches(115) +
                    itemIndex * pixelsToInches(162), // Start Y-coordinate
                  w: 0,
                  h: pixelsToInches(32), // Height (the difference between start and end Y-coordinates)
                  line: { color: "eeeeee", width: 1 }, // Line color and width
                });
              }
            }
            j += 97;
          });
        }
        if (item.key == "genders") {
          let j = 0;
          let all = item.items;
          item.items.forEach((item: any, index: any) => {
            if (typeof item == "object") {
              slide.addImage({
                path: `${process.env.PUBLIC_URL}/imgs/${
                  item.key == "Male" ? "male" : "female"
                }.svg`,
                x:
                  lang == "en"
                    ? startX +
                      pixelsToInches(179) +
                      pixelsToInches(x) +
                      pixelsToInches(j)
                    : rtlItems(
                        startX -
                          pixelsToInches(179) -
                          pixelsToInches(x) -
                          pixelsToInches(j),
                        cardWidth,
                        pixelsToInches(32),
                        gutter
                      ),
                y:
                  pixelsToInches(24 * (itemIndex + 1)) +
                  startY +
                  pixelsToInches(114) +
                  itemIndex * pixelsToInches(162),
                w: pixelsToInches(32),
                h: pixelsToInches(32),
              });
              slide.addText(`${Math.round(item.percentage)}%`, {
                x:
                  lang == "en"
                    ? startX +
                      pixelsToInches(179) +
                      pixelsToInches(20) +
                      pixelsToInches(x) +
                      pixelsToInches(j)
                    : rtlItems(
                        startX -
                          pixelsToInches(179) -
                          pixelsToInches(20) -
                          pixelsToInches(x) -
                          pixelsToInches(j),
                        cardWidth,
                        pixelsToInches(65),
                        gutter
                      ),
                y:
                  pixelsToInches(24 * (itemIndex + 1)) +
                  startY +
                  pixelsToInches(120) +
                  itemIndex * pixelsToInches(162),
                w: pixelsToInches(65),
                h: pixelsToInches(18),
                fontSize: getFontSize(16),
                bold: true,
                fontFace: lang == "en" ? "Arial" : "Segoe UI",

                align: lang == "en" ? "left" : "right",
              });
              if (index == 0 && all.length > 1) {
                slide.addShape(pptx.ShapeType.line, {
                  x:
                    lang == "en"
                      ? startX +
                        pixelsToInches(179) +
                        pixelsToInches(80) +
                        pixelsToInches(x) +
                        pixelsToInches(j)
                      : rtlItems(
                          startX -
                            pixelsToInches(179) -
                            pixelsToInches(80) -
                            pixelsToInches(x) -
                            pixelsToInches(j),
                          cardWidth,
                          0,
                          gutter
                        ),
                  y:
                    pixelsToInches(24 * (itemIndex + 1)) +
                    startY +
                    pixelsToInches(115) +
                    itemIndex * pixelsToInches(162), // Start Y-coordinate
                  w: 0,
                  h: pixelsToInches(32), // Height (the difference between start and end Y-coordinates)
                  line: { color: "eeeeee", width: 1 }, // Line color and width
                });
              }
            } else {
              slide.addImage({
                path: `${process.env.PUBLIC_URL}/imgs/${item}.svg`,
                x:
                  lang == "en"
                    ? startX +
                      pixelsToInches(179) +
                      pixelsToInches(x) +
                      pixelsToInches(j)
                    : rtlItems(
                        startX -
                          pixelsToInches(179) -
                          pixelsToInches(x) -
                          pixelsToInches(j),
                        cardWidth,
                        pixelsToInches(32),
                        gutter
                      ),
                y:
                  pixelsToInches(24 * (itemIndex + 1)) +
                  startY +
                  pixelsToInches(114) +
                  itemIndex * pixelsToInches(162),
                w: pixelsToInches(32),
                h: pixelsToInches(32),
              });
              slide.addText(`-`, {
                x:
                  lang == "en"
                    ? startX +
                      pixelsToInches(179) +
                      pixelsToInches(20) +
                      pixelsToInches(x) +
                      pixelsToInches(j)
                    : rtlItems(
                        startX -
                          pixelsToInches(179) -
                          pixelsToInches(20) -
                          pixelsToInches(x) -
                          pixelsToInches(j),
                        cardWidth,
                        pixelsToInches(65),
                        gutter
                      ),
                y:
                  pixelsToInches(24 * (itemIndex + 1)) +
                  startY +
                  pixelsToInches(120) +
                  itemIndex * pixelsToInches(162),
                w: pixelsToInches(65),
                h: pixelsToInches(18),
                fontSize: getFontSize(16),
                bold: true,
                fontFace: lang == "en" ? "Arial" : "Segoe UI",

                align: lang == "en" ? "left" : "right",
              });
              if (index == 0 && all.length > 1) {
                slide.addShape(pptx.ShapeType.line, {
                  x:
                    lang == "en"
                      ? startX +
                        pixelsToInches(179) +
                        pixelsToInches(80) +
                        pixelsToInches(x) +
                        pixelsToInches(j)
                      : rtlItems(
                          startX -
                            pixelsToInches(179) -
                            pixelsToInches(80) -
                            pixelsToInches(x) -
                            pixelsToInches(j),
                          cardWidth,
                          0,
                          gutter
                        ),
                  y:
                    pixelsToInches(24 * (itemIndex + 1)) +
                    startY +
                    pixelsToInches(115) +
                    itemIndex * pixelsToInches(162), // Start Y-coordinate
                  w: 0,
                  h: pixelsToInches(32), // Height (the difference between start and end Y-coordinates)
                  line: { color: "eeeeee", width: 1 }, // Line color and width
                });
              }
            }

            j += 97;
          });
        }
        if (item.key == "ageRanges") {
          slide.addImage({
            path: `${process.env.PUBLIC_URL}/imgs/age-limit.svg`,
            x:
              lang == "en"
                ? startX + pixelsToInches(179) + pixelsToInches(x)
                : rtlItems(
                    startX - pixelsToInches(179) - pixelsToInches(x),
                    cardWidth,
                    pixelsToInches(32),
                    gutter
                  ),
            y:
              pixelsToInches(24 * (itemIndex + 1)) +
              startY +
              pixelsToInches(114) +
              itemIndex * pixelsToInches(162),
            w: pixelsToInches(32),
            h: pixelsToInches(32),
          });
          slide.addText(
            item.items.from || item.items.to
              ? item.items.from && item.items.to
                ? `${item.items.from}-${item.items.to}`
                : item.items.from
              : "-",
            {
              x:
                lang == "en"
                  ? startX +
                    pixelsToInches(179) +
                    pixelsToInches(20) +
                    pixelsToInches(x)
                  : rtlItems(
                      startX -
                        pixelsToInches(179) -
                        pixelsToInches(20) -
                        pixelsToInches(x),
                      cardWidth,
                      pixelsToInches(85),
                      gutter
                    ),
              y:
                pixelsToInches(24 * (itemIndex + 1)) +
                startY +
                pixelsToInches(118) +
                itemIndex * pixelsToInches(162),
              w: pixelsToInches(85),
              h: pixelsToInches(24),
              fontSize: getFontSize(16),
              bold: true,
              align: lang == "en" ? "left" : "right",
              fontFace: lang == "en" ? "Arial" : "Segoe UI",
            }
          );

          slide.addShape(pptx.ShapeType.line, {
            x:
              lang == "en"
                ? startX +
                  pixelsToInches(179) +
                  pixelsToInches(100) +
                  pixelsToInches(x)
                : rtlItems(
                    startX -
                      pixelsToInches(179) -
                      pixelsToInches(100) -
                      pixelsToInches(x),
                    cardWidth,
                    0,
                    gutter
                  ),
            y:
              pixelsToInches(24 * (itemIndex + 1)) +
              startY +
              pixelsToInches(115) +
              itemIndex * pixelsToInches(162), // Start Y-coordinate
            w: 0,
            h: pixelsToInches(32), // Height (the difference between start and end Y-coordinates)
            line: { color: "eeeeee", width: 1 }, // Line color and width
          });

          slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
            x:
              lang == "en"
                ? startX +
                  pixelsToInches(179) +
                  pixelsToInches(121) +
                  pixelsToInches(x)
                : rtlItems(
                    startX -
                      pixelsToInches(179) -
                      pixelsToInches(121) -
                      pixelsToInches(x),
                    cardWidth,
                    pixelsToInches(46),
                    gutter
                  ),
            y:
              pixelsToInches(24 * (itemIndex + 1)) +
              startY +
              pixelsToInches(118) +
              itemIndex * pixelsToInches(162),
            w: pixelsToInches(46),
            h: pixelsToInches(24),
            fill: "ffffff",
            line: "eeeeee",
            rectRadius: 0.1,
          });
          let w = item.items.percentage ? 70 : 55;
          slide.addText(
            item.items.percentage
              ? `${Math.round(item.items.percentage)}%`
              : "-",
            {
              x:
                lang == "en"
                  ? startX +
                    pixelsToInches(175) +
                    pixelsToInches(121) +
                    pixelsToInches(x)
                  : rtlItems(
                      startX -
                        pixelsToInches(175) -
                        pixelsToInches(121) -
                        pixelsToInches(x),
                      cardWidth,
                      pixelsToInches(w),
                      gutter
                    ),
              y:
                pixelsToInches(24 * (itemIndex + 1)) +
                startY +
                pixelsToInches(118) +
                itemIndex * pixelsToInches(162),
              w: pixelsToInches(w),
              h: pixelsToInches(24),
              fontSize: getFontSize(16),
              align: item.items.percentage
                ? lang == "en"
                  ? "left"
                  : "right"
                : "center",
              fontFace: lang == "en" ? "Arial" : "Segoe UI",
              bold: item.items.percentage ? false : true,
            }
          );
        }
      }
      x += 258;
    }
  }

  slide.addShape(pptx.ShapeType.line, {
    x:
      lang == "en"
        ? startX + pixelsToInches(179)
        : rtlItems(startX - pixelsToInches(179), cardWidth, 0, gutter), // X-coordinate
    y:
      pixelsToInches(24 * (itemIndex + 1)) +
      startY +
      itemIndex * pixelsToInches(162), // Start Y-coordinate
    w: 0,
    h: pixelsToInches(162), // Height (the difference between start and end Y-coordinates)
    line: { color: "eeeeee", width: 1 }, // Line color and width
  });

  slide.addText(item.name, {
    w: pixelsToInches(179),
    h: pixelsToInches(26),
    x:
      lang == "en"
        ? startX
        : rtlItems(startX, cardWidth, pixelsToInches(179), gutter),
    y: imgStartY + socialIconHeight + pixelsToInches(8),
    bold: true,
    fontSize: getFontSize(18),
    align: "center",
    color: "#1f1f1f",
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
  });
  let followersOptions = {
    x:
      lang == "en"
        ? startX
        : rtlItems(startX, cardWidth, pixelsToInches(179), gutter),
    y: imgStartY + socialIconHeight + pixelsToInches(34),
    w: pixelsToInches(179),
    h: pixelsToInches(21),
    fontSize: getFontSize(12),
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
    color: "#4b4b4b",
    align: "center",
    rtlMode: lang == "en" ? "ltr" : "rtl",
  };
  item.followersCount &&
    slide.addText(
      lang == "en"
        ? `(${formatNumber(item.followersCount)} ${
            lang == "en" ? "Followers" : "متابع"
          })`
        : ` ${formatNumber(item.followersCount)})${
            lang == "en" ? "Followers" : "متابع"
          }(`,
      followersOptions
    );

  item.services.forEach((service: any, index: any) => {
    platformService(
      item.services.length,
      imgStartX + socialIconWidth + pixelsToInches(55.5),
      pixelsToInches(24 * (itemIndex + 1)) +
        startY -
        pixelsToInches(itemIndex * 1.5) +
        itemIndex * pixelsToInches(164),
      slide,
      pptx,
      service,
      index,
      withPrices,
      lang,
      cardWidth
    );
  });
};

const platformService = (
  totalCount: number,
  startX: number,
  startY: number,
  slide: any,
  pptx: any,
  service: any,
  itemIndex: number,
  withPrices: boolean,
  lang: string,
  containerCardWidth: number
) => {
  let cardsGap = pixelsToInches(0);
  let cardWidth = pixelsToInches(145.3);
  let cardHeight = pixelsToInches(98);
  let startPointX =
    startX + pixelsToInches(10) + itemIndex * (cardWidth + cardsGap);

  let startPointXAr =
    startX - pixelsToInches(10) - itemIndex * (cardWidth + cardsGap);

  let startPointY = startY;
  const cardOptions = {
    x: startPointX,
    y: startPointY,
    w: cardWidth,
    h: cardHeight,
    fill: "ffffff",
    line: "eeeeee",
  };

  const serviceNameOptions = {
    x:
      lang == "en"
        ? startPointX + pixelsToInches(16)
        : rtlItems(
            startPointXAr - pixelsToInches(16),
            cardWidth,
            pixelsToInches(120),
            pixelsToInches(293.5)
          ),
    y: withPrices
      ? startPointY + (cardHeight / 2 - pixelsToInches(24))
      : startPointY +
        pixelsToInches(15) +
        (cardHeight / 2 - pixelsToInches(24)),
    fontSize: getFontSize(14),
    h: pixelsToInches(15),
    w: pixelsToInches(120),
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
    align: lang == "en" ? "left" : "right",
    color: "#4b4b4b",
  };

  const servicePriceOptions = {
    x:
      lang == "en"
        ? startPointX + pixelsToInches(16)
        : rtlItems(
            startPointXAr - pixelsToInches(16),
            cardWidth,
            cardWidth,
            pixelsToInches(293.5)
          ),
    y: startPointY + (cardHeight / 2 - pixelsToInches(24)) + pixelsToInches(20),
    fontSize: getFontSize(18),
    h: pixelsToInches(26),
    bold: true,
    color: "#1F1F1F",
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
    w: cardWidth,
    align: lang == "en" ? "left" : "right",
    rtlMode: lang == "en" ? "ltr" : "rtl",
  };
  // const serviceCurrenctOptions = {
  //   x:
  //     lang == "en"
  //       ? startPointX + pixelsToInches(65)
  //       : startPointX - pixelsToInches(12),
  //   y: startPointY + pixelsToInches(24),
  //   fontSize: pixelsToPoints(10),
  //   h: pixelsToInches(17),
  //   color: "#8E8E8E",
  //   fontFace: lang == "en" ? "Arial" : "Segoe UI",
  //   w: pixelsToInches(43),
  //   align: lang == "en" ? "left" : "right",
  //   rtlMode: lang == "ar",
  // };

  let currency =
    lang == "en"
      ? service.currencyKey
      : service.currencyKey.toLowerCase() == "egp"
      ? "ج.م"
      : service.currencyKey.toLowerCase() == "usd"
      ? "د.أ"
      : "ر.س ";
  // if (itemIndex + 1 < totalCount) {
  //   slide.addShape(pptx.ShapeType.rect, cardOptions);
  // }

  if (lang == "en" && itemIndex != 0) {
    slide.addShape(pptx.ShapeType.line, {
      x:
        lang == "en"
          ? startPointX
          : rtlItems(startPointXAr, cardWidth, 0, pixelsToInches(438.8)), // X-coordinate
      y: startPointY, // Start Y-coordinate
      w: 0,
      h: cardHeight, // Height (the difference between start and end Y-coordinates)
      line: { color: "eeeeee", width: 1 }, // Line color and width
    });
  }

  if (lang == "ar" && itemIndex + 1 != totalCount) {
    slide.addShape(pptx.ShapeType.line, {
      x: rtlItems(startPointXAr, cardWidth, 0, pixelsToInches(438.8)), // X-coordinate
      y: startPointY, // Start Y-coordinate
      w: 0,
      h: cardHeight, // Height (the difference between start and end Y-coordinates)
      line: { color: "eeeeee", width: 1 }, // Line color and width
    });
  }
  slide.addText(service.name, serviceNameOptions);
  slide.addText(service.currency, serviceNameOptions);

  if (withPrices && service.price) {
    slide.addText(
      lang == "en"
        ? [
            {
              text: `${moneyFormatter(service.price)}`,
              options: {
                color: "#1f1f1f",
              },
            },
            {
              text: ` ${currency}`,
              options: {
                color: "8e8e8e",
                fontSize: getFontSize(12),
                bold: false,
              },
            },
          ]
        : [
            {
              text: ` ${moneyFormatter(service.price)}`,
              options: {
                color: "#1f1f1f",
                align: lang == "en" ? "left" : "right",
                rtlMode: lang == "en" ? "ltr" : "rtl",
              },
            },
            {
              text: `${currency}`,
              options: {
                color: "8e8e8e",
                fontSize: getFontSize(12),
                bold: false,
                align: lang == "en" ? "left" : "right",
                rtlMode: lang == "en" ? "ltr" : "rtl",
              },
            },
          ],
      servicePriceOptions
    );
  } else {
    if (withPrices) {
      slide.addText(lang == "en" ? "Not Priced" : "غير مسعر", {
        ...servicePriceOptions,
        bold: false,
      });
    }
  }

  // service.price &&
  //   withPrices &&
  //   slide.addText(currency, serviceCurrenctOptions);
};

const generatePPtCover = async (
  lang: string,
  details: any,
  pptx: any,
  influencers: any,
  agencyDetails: any
) => {
  let services = [];
  influencers.forEach((inf: any) => {
    inf.socialPlatforms.forEach((p: any) => {
      p.services.forEach((s: any) => services.push(s));
    });
  });
  let slide = pptx.addSlide();

  slide.addImage({
    path: `${process.env.PUBLIC_URL}/imgs/ppt-bg.svg`,
    x:
      lang == "en"
        ? pixelsToInches(55)
        : rtl(pixelsToInches(55), pixelsToInches(1050)),
    w: pixelsToInches(1050),
    h: pixelsToInches(1050),
    y: pixelsToInches(12),
  });

  slide.addImage({
    path:
      agencyDetails?.logoUrl ||
      `${process.env.PUBLIC_URL}/imgs/ppt-lavad-logo-en.svg`,
    x:
      lang == "en"
        ? pixelsToInches(117)
        : rtl(pixelsToInches(109), pixelsToInches(300)),
    w: pixelsToInches(300),
    h: pixelsToInches(80),
    y: pixelsToInches(229),
    sizing: {
      type: "contain",
    },
  });

  slide.addShape(pptx.ShapeType.rect, {
    fill: {
      color: agencyDetails?.primaryColor
        ? getPalette({
            color: agencyDetails?.primaryColor,
            name: "primary",
          }).primary["500"].split("#")[1]
        : "5F5BF9",
    },
    x:
      lang == "en"
        ? pixelsToInches(1190)
        : rtl(pixelsToInches(1190), pixelsToInches(730)),
    w: pixelsToInches(730),
    h: pixelsToInches(540),
    y: pixelsToInches(0),
  });
  slide.addImage({
    path: `${process.env.PUBLIC_URL}/imgs/promotion.svg`,
    x:
      lang == "en"
        ? pixelsToInches(1495)
        : rtl(pixelsToInches(1495), pixelsToInches(120)),
    w: pixelsToInches(120),
    h: pixelsToInches(120),
    y: pixelsToInches(146),
  });
  slide.addText(lang == "en" ? "Total Ads" : "إجمالي الإعلانات", {
    x:
      lang == "en"
        ? pixelsToInches(1190)
        : rtl(pixelsToInches(1190), pixelsToInches(730)),
    y: pixelsToInches(266),
    w: pixelsToInches(730),
    fontSize: getFontSize(32),
    color: "#ffffff",
    align: "center",
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
  });
  // placeholder
  slide.addText(services.length, {
    x:
      lang == "en"
        ? pixelsToInches(1190)
        : rtl(pixelsToInches(1190), pixelsToInches(730)),
    y: pixelsToInches(322),
    w: pixelsToInches(730),
    fontSize: getFontSize(82),
    color: "#ffffff",
    align: "center",
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
  });
  slide.addShape(pptx.ShapeType.rect, {
    fill: {
      color: agencyDetails?.primaryColor
        ? getPalette({
            color: agencyDetails?.primaryColor,
            name: "primary",
          }).primary["700"].split("#")[1]
        : "432FD3",
    },
    x:
      lang == "en"
        ? pixelsToInches(1190)
        : rtl(pixelsToInches(1190), pixelsToInches(730)),
    w: pixelsToInches(730),
    h: pixelsToInches(540),
    y: pixelsToInches(540),
  });
  slide.addImage({
    path: `${process.env.PUBLIC_URL}/imgs/affiliate.svg`,
    x:
      lang == "en"
        ? pixelsToInches(1495)
        : rtl(pixelsToInches(1495), pixelsToInches(120)),
    w: pixelsToInches(120),
    h: pixelsToInches(120),
    y: pixelsToInches(696),
  });

  slide.addText(lang == "en" ? "Total influencers" : "مجموع المؤثرين", {
    x:
      lang == "en"
        ? pixelsToInches(1190)
        : rtl(pixelsToInches(1190), pixelsToInches(730)),
    y: pixelsToInches(816),
    w: pixelsToInches(730),
    fontSize: getFontSize(32),
    color: "#ffffff",
    align: "center",
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
  });

  // placeholder
  slide.addText(influencers.length, {
    x:
      lang == "en"
        ? pixelsToInches(1190)
        : rtl(pixelsToInches(1190), pixelsToInches(730)),
    y: pixelsToInches(872),
    w: pixelsToInches(730),
    fontSize: getFontSize(82),
    color: "#ffffff",
    align: "center",
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
  });
  slide.addText(details?.name as string, {
    w: pixelsToInches(925),
    h: pixelsToInches(680),
    x:
      lang == "en"
        ? pixelsToInches(117)
        : rtl(pixelsToInches(117), pixelsToInches(925)),
    y: pixelsToInches(319 + 32),
    bold: true,
    fontSize: getFontSize(110),
    color: "#1F1F1F",
    align: lang == "en" ? "left" : "right",
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
    valign: "top",
  });
};

export const generateCardHeader = async (
  total: number,
  itemIndex: number,
  x: number,
  y: number,
  inf: any,
  details: any,
  pptx: any,
  slide: any,
  lang: string,
  cardX: number,
  cardWidth: number
) => {
  const imgWidth = pixelsToInches(140);
  let gutter = pixelsToInches(40);
  console.log(x, "x in header", cardWidth);
  slide.addShape(pptx.ShapeType.rect, {
    fill: { color: "FAFAFA" },
    x,
    y,
    w: cardWidth,
    h: pixelsToInches(286),
  });

  slide.addImage({
    path: inf.avatarUrl,
    y: y + gutter,
    x: lang == "en" ? x + gutter : rtlItems(x, cardWidth, imgWidth, gutter),
    w: imgWidth,
    h: imgWidth,
    rounding: true,
    sizing: {
      type: "cover",
      w: imgWidth,
      h: imgWidth,
    },
  });

  slide.addText(inf.name, {
    x:
      lang == "en"
        ? x + imgWidth + pixelsToInches(24) + gutter
        : rtlItems(
            x - imgWidth - gutter,
            cardWidth,
            pixelsToInches(444),
            gutter
          ),
    y: y + gutter + pixelsToInches(10),
    h: pixelsToInches(32),
    w: pixelsToInches(444),
    bold: true,
    fontSize: getFontSize(24),
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
    align: lang == "en" ? "left" : "right",
  });

  slide.addImage({
    path: `${process.env.PUBLIC_URL}/imgs/location-pin.svg`,
    x:
      lang == "en"
        ? x + imgWidth + pixelsToInches(32) + gutter
        : rtlItems(
            x - imgWidth - pixelsToInches(8) - gutter,
            cardWidth,
            pixelsToInches(24),
            gutter
          ),
    y: y + gutter + pixelsToInches(10) + pixelsToInches(44),
    w: pixelsToInches(24),
    h: pixelsToInches(24),
  });

  slide.addText(inf.residency, {
    x:
      lang == "en"
        ? x + imgWidth + pixelsToInches(48) + gutter
        : rtlItems(
            x - imgWidth - pixelsToInches(24) - gutter,
            cardWidth,
            pixelsToInches(444),
            gutter
          ),
    y: y + gutter + pixelsToInches(18) + pixelsToInches(32),
    h: pixelsToInches(32),
    w: pixelsToInches(444),
    fontSize: getFontSize(14),
    fontFace: lang == "en" ? "Arial" : "Segoe UI",
    align: lang == "en" ? "left" : "right",
    color: "1f1f1f",
    rtlMode: lang == "en" ? false : true,
  });

  // mawthok
  if (inf.metaData.hasMawthok) {
    slide.addImage({
      path: `${process.env.PUBLIC_URL}/imgs/Mawthooq.svg`,
      y: y + gutter + pixelsToInches(10),
      x:
        lang == "en"
          ? x + imgWidth + pixelsToInches(14) + gutter + pixelsToInches(600)
          : rtlItems(
              x - imgWidth - pixelsToInches(4) - gutter - pixelsToInches(600),
              cardWidth,
              pixelsToInches(48),
              gutter
            ),
      w: pixelsToInches(48),
      h: pixelsToInches(48),
      shadow: {
        type: "outer",
        color: "000000",
        blur: 5,
        offset: 1,
        angle: 180,
        opacity: 0.2,
      },
    });

    slide.addText(lang == "en" ? "Mawthok" : "موثوق", {
      y: y + gutter + pixelsToInches(22),
      x:
        lang == "en"
          ? x +
            imgWidth +
            pixelsToInches(22) +
            gutter +
            pixelsToInches(600) +
            pixelsToInches(36)
          : rtlItems(
              x -
                imgWidth -
                pixelsToInches(14) -
                gutter -
                pixelsToInches(600) -
                pixelsToInches(36),
              cardWidth,
              pixelsToInches(100),
              gutter
            ),
      w: pixelsToInches(100),
      h: pixelsToInches(22),
      fontSize: getFontSize(14),
      bold: true,
      align: lang == "en" ? "left" : "right",
      fontFace: lang == "en" ? "Arial" : "Segoe UI",
    });
  }

  // commit status
  if (inf.metaData?.commitmentLevelKey) {
    slide.addImage({
      path: getCommitmentImgAndName(inf.metaData.commitmentLevelKey, lang).img,
      y: y + gutter + pixelsToInches(10) + pixelsToInches(88),
      x:
        lang == "en"
          ? x + imgWidth + pixelsToInches(34) + gutter
          : rtlItems(
              x - imgWidth - pixelsToInches(10) - gutter,
              cardWidth,
              pixelsToInches(40),
              gutter
            ),
      w: pixelsToInches(40),
      h: pixelsToInches(40),
    });

    slide.addText(
      getCommitmentImgAndName(inf.metaData.commitmentLevelKey, lang).name,
      {
        y:
          y +
          gutter +
          pixelsToInches(10) +
          pixelsToInches(88) +
          pixelsToInches(10),
        x:
          lang == "en"
            ? x +
              imgWidth +
              pixelsToInches(34) +
              gutter +
              pixelsToInches(8) +
              pixelsToInches(28)
            : rtlItems(
                x -
                  imgWidth -
                  pixelsToInches(10) -
                  gutter -
                  pixelsToInches(8) -
                  pixelsToInches(28),
                cardWidth,
                pixelsToInches(186),
                gutter
              ),
        w: pixelsToInches(186),
        h: pixelsToInches(22),
        fontSize: getFontSize(14),
        bold: true,
        align: lang == "en" ? "left" : "right",
        fontFace: lang == "en" ? "Arial" : "Segoe UI",
      }
    );
  }

  // insights

  if (inf.metaData.allowInsightsSharing) {
    if (!inf.metaData?.commitmentLevelKey) {
      slide.addImage({
        path: `${process.env.PUBLIC_URL}/imgs/insights.svg`,
        y: y + gutter + pixelsToInches(10) + pixelsToInches(88),
        x:
          lang == "en"
            ? x + imgWidth + pixelsToInches(34) + gutter
            : rtlItems(
                x - imgWidth - pixelsToInches(10) - gutter,
                cardWidth,
                pixelsToInches(40),
                gutter
              ),
        w: pixelsToInches(40),
        h: pixelsToInches(40),
      });

      slide.addText(lang == "en" ? "Share Insights" : "مشاركة الإحصائيات", {
        y:
          y +
          gutter +
          pixelsToInches(10) +
          pixelsToInches(88) +
          pixelsToInches(10),
        x:
          lang == "en"
            ? x +
              imgWidth +
              pixelsToInches(34) +
              gutter +
              pixelsToInches(8) +
              pixelsToInches(28)
            : rtlItems(
                x -
                  imgWidth -
                  pixelsToInches(10) -
                  gutter -
                  pixelsToInches(8) -
                  pixelsToInches(28),
                cardWidth,
                pixelsToInches(186),
                gutter
              ),
        w: pixelsToInches(186),
        h: pixelsToInches(22),
        fontSize: getFontSize(14),
        bold: true,
        align: lang == "en" ? "left" : "right",
        fontFace: lang == "en" ? "Arial" : "Segoe UI",
      });
    } else {
      slide.addImage({
        path: `${process.env.PUBLIC_URL}/imgs/insights.svg`,
        y: y + gutter + pixelsToInches(10) + pixelsToInches(88),
        x:
          lang == "en"
            ? x + imgWidth + pixelsToInches(34) + gutter + pixelsToInches(240)
            : rtlItems(
                x -
                  imgWidth -
                  pixelsToInches(10) -
                  gutter -
                  pixelsToInches(240),
                cardWidth,
                pixelsToInches(40),
                gutter
              ),
        w: pixelsToInches(40),
        h: pixelsToInches(40),
      });

      slide.addText(lang == "en" ? "Share Insights" : "مشاركة الإحصائيات", {
        y:
          y +
          gutter +
          pixelsToInches(10) +
          pixelsToInches(88) +
          pixelsToInches(10),
        x:
          lang == "en"
            ? x +
              imgWidth +
              pixelsToInches(34) +
              gutter +
              pixelsToInches(8) +
              pixelsToInches(28) +
              pixelsToInches(240)
            : rtlItems(
                x -
                  imgWidth -
                  pixelsToInches(10) -
                  gutter -
                  pixelsToInches(8) -
                  pixelsToInches(28) -
                  pixelsToInches(240),
                cardWidth,
                pixelsToInches(186),
                gutter
              ),
        w: pixelsToInches(186),
        h: pixelsToInches(22),
        fontSize: getFontSize(14),
        bold: true,
        align: lang == "en" ? "left" : "right",
        fontFace: lang == "en" ? "Arial" : "Segoe UI",
      });
    }
  }

  // niche is optional
  // review comments

  if (inf.influencerNicheKeys?.length) {
    slide.addText(lang == "en" ? "Niche" : "منافذ المؤثر", {
      x:
        lang == "en"
          ? x + gutter
          : rtlItems(x, cardWidth, pixelsToInches(214), gutter),
      y: y + gutter + pixelsToInches(16) + imgWidth,
      color: "#1f1f1f",
      fontSize: getFontSize(14),
      w: pixelsToInches(214),
      h: pixelsToInches(18),
      fontFace: lang == "en" ? "Arial" : "Segoe UI",
      align: lang == "en" ? "left" : "right",
      bold: true,
    });
    slide.addText(inf.influencerNicheKeys.map((k: any) => k.name).join(", "), {
      x:
        lang == "en"
          ? x + gutter
          : rtlItems(x, cardWidth, pixelsToInches(214), gutter),
      y: y + gutter + pixelsToInches(36) + imgWidth + pixelsToInches(2),
      color: "#4B4B4B",
      fontSize: getFontSize(14),
      w: pixelsToInches(214),
      h: pixelsToInches(44),
      fontFace: lang == "en" ? "Arial" : "Segoe UI",
      align: lang == "en" ? "left" : "right",
      rtlMode: lang == "en" ? "ltr" : "rtl",
    });
  }

  // bio is optional
  // review comments
  if ((lang == "en" && inf.bio?.en) || (lang == "ar" && inf.bio?.ar)) {
    slide.addShape(pptx.ShapeType.line, {
      x:
        lang == "en"
          ? x + pixelsToInches(290)
          : rtlItems(x - pixelsToInches(290), cardWidth, 0, 0), // X-coordinate
      y: y + gutter + pixelsToInches(14) + imgWidth, // Start Y-coordinate
      w: 0,
      h: pixelsToInches(66), // Height (the difference between start and end Y-coordinates)
      line: { color: "eeeeee", width: 1 }, // Line color and width
    });
    slide.addText(lang == "en" ? "Bio" : "السيرة الذاتية", {
      x:
        lang == "en"
          ? x + pixelsToInches(303)
          : rtlItems(
              x - pixelsToInches(303),
              cardWidth,
              pixelsToInches(568),
              0
            ),
      y: y + gutter + pixelsToInches(16) + imgWidth,
      color: "#1f1f1f",
      fontSize: getFontSize(14),
      w: pixelsToInches(568),
      h: pixelsToInches(18),
      fontFace: lang == "en" ? "Arial" : "Segoe UI",
      align: lang == "en" ? "left" : "right",
      bold: true,
    });
    slide.addText(
      lang == "en"
        ? inf.bio?.en
        : inf.bio?.ar.replace(")", "(").replace("(", ")"),
      {
        x:
          lang == "en"
            ? x + pixelsToInches(303)
            : rtlItems(
                x - pixelsToInches(303),
                cardWidth,
                pixelsToInches(568),
                0
              ),
        y: y + gutter + pixelsToInches(36) + imgWidth + pixelsToInches(2),
        color: "#4B4B4B",
        fontSize: getFontSize(14),
        w: pixelsToInches(568),
        h: pixelsToInches(44),
        fontFace: lang == "en" ? "Arial" : "Segoe UI",
        align: lang == "en" ? "left" : "right",
        rtlMode: lang == "en" ? "ltr" : "rtl",
      }
    );
  }
  // if (inf.isFeatured) {
  //   slide.addImage({
  //     path: `${process.env.PUBLIC_URL}/imgs/verified.png`,
  //     x:
  //       lang == "en"
  //         ? x + pixelsToInches(504)
  //         : rtlItems(x + pixelsToInches(110), 0, pixelsToInches(20), gutter),
  //     w: pixelsToInches(20),
  //     h: pixelsToInches(20),
  //     sizing: {
  //       type: "contain",
  //       x: x + pixelsToInches(504),
  //       w: pixelsToInches(20),
  //       h: pixelsToInches(20),
  //     },
  //     y: y + pixelsToInches(16),
  //   });
  //   slide.addText(lang == "en" ? "Trusted" : "موثوق", {
  //     x:
  //       lang == "en"
  //         ? x + pixelsToInches(504 + 4 + 10)
  //         : rtlItems(x + pixelsToInches(110), 0, pixelsToInches(90), gutter),
  //     y: y + pixelsToInches(16),
  //     color: "#000000",
  //     fontSize: pixelsToPoints(14),
  //     fontFace: lang == "en" ? "Arial" : "Segoe UI",
  //     w: pixelsToInches(75),
  //     h: pixelsToInches(20),
  //     align: lang == "en" ? "left" : "right",
  //   });
  // }
  if (inf.metaData.isRecommended) {
    slide.addImage({
      path:
        lang == "en"
          ? `${process.env.PUBLIC_URL}/imgs/recommended-en.svg`
          : `${process.env.PUBLIC_URL}/imgs/recommended-ar.svg`,
      y: y + gutter + pixelsToInches(140 - 32),
      x:
        lang == "en"
          ? x + gutter
          : rtlItems(x, cardWidth, pixelsToInches(146), gutter),
      w: pixelsToInches(146),
      h: pixelsToInches(32),
    });
  }
  // add seperator
  slide.addShape(pptx.ShapeType.line, {
    x: pixelsToInches(1920 / 2),
    y: 0, // Start Y-coordinate
    w: 0,
    h: pixelsToInches(1080), // Height (the difference between start and end Y-coordinates)
    line: { color: "eeeeee", width: 1 }, // Line color and width
  });
};

function mapLookupEnum(item: any, lng: string, lookupKey: string) {
  let itemLowerCase = item.toLowerCase();
  if (lookupKey == "ageRanges") {
    if (t(itemLowerCase, { lng: lng }).split("-").length > 1) {
      return {
        name: t(itemLowerCase, { lng: lng }),
        key: item,
        from: t(itemLowerCase, { lng: lng }).split("-")[0],
        to: t(itemLowerCase, { lng: lng }).split("-")[1],
      };
    } else {
      console.log(itemLowerCase, "itemLowerCase");
      return {
        name: t(itemLowerCase, { lng: lng }),
        key: item,
        from: t(itemLowerCase, { lng: lng }),
        to: null,
      };
    }
  } else {
    return {
      name: t(itemLowerCase, { lng: lng }),
      key: item,
    };
  }
}

const isArrayOfStrings = (arr: any) => {
  return arr.every((item: any) => typeof item === "string");
};

export const lookupsMapper = (lookupData: any, lng: string) => {
  const lookups: Object[] = lookupData;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  for (let lookupKey in lookups) {
    const lookupValue = lookups[lookupKey];

    if (Array.isArray(lookupValue) && isArrayOfStrings(lookupValue)) {
      lookups[lookupKey] = lookupValue.map((x: any) =>
        mapLookupEnum(x, lng, lookupKey)
      );
    }
  }
  return lookups;
};

export function mapExportExcelInfsData(infs: any, lookups: any) {
  let servicesArray: any = [];

  infs.forEach((influencer: any) => {
    influencer.socialPlatforms.forEach((platform: any) => {
      platform.services.forEach((service: any) => {
        servicesArray.push([
          influencer.name,
          influencer.influencerNicheKeys.length
            ? influencer.influencerNicheKeys.map((a: any) => a.name).join(", ")
            : "_",
          platform.name,
          platform.influencerType,
          service.name,
          lookups.lineItemBookingStatuses.find(
            (a: any) => a.key == service.bookingStatusKey
          ).name,
          service.price ? service.price : "_",
          platform.followersCount ? platform.followersCount : "_",
          platform.socialPlatformUrl ? platform.socialPlatformUrl : "_",
        ]);
      });
    });
  });

  return servicesArray;
}

export async function exportExcelInfs(
  translate: any,
  mappedInfs: any,
  fileName: string
) {
  const headers = [
    "name",
    "niches",
    "platform",
    "influencer_type",
    "service",
    "status",
    "servicePrice",
    "followersCount",
    "socialMediaLink",
  ].map((i) => translate(i));

  // Convert data to worksheet
  const ws = XLSX.utils.aoa_to_sheet([headers, ...mappedInfs]);

  const numRows = mappedInfs.length;

  for (let r = 1; r <= numRows; r++) {
    const cellRef = `A${r + 1}`;
    const linkCell = ws[`I${r + 1}`];

    const cell = ws[cellRef];
    if (cell && linkCell.v && isValidURL(linkCell.v)) {
      cell.s = {
        font: { color: { rgb: "0000FF" }, underline: true },
      };
      cell.l = {
        Target: linkCell.v,
        Tooltip: "Click to visit",
      };
      cell.t = "s";
    }
  }

  // Create a workbook and add the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write the workbook to a file
  return XLSX.writeFile(wb, fileName);

  console.log("Excel file created successfully.");
}
