import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { formatNumber, isValidURL } from "../../Utils/helpers";
import { Tooltip, message } from "antd";
import { HiInformationCircle } from "react-icons/hi2";
import WarningImg from "../../assets/imgs/Attention.svg"

const InfluencerDetailsPlatformItem = ({
  platformImg,
  platformName,
  platfromLink,
  followersCount,
  isVerified,
  platformKey,
  isActive
}: {
  platformImg: string;
  platformName: string;
  platfromLink: string;
  followersCount: number;
  isVerified: boolean;
  platformKey: string;
  isActive: boolean;
}) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const handleOpenMessage = () => {
    if (
      window.matchMedia("(max-width:1200px)").matches &&
      (!platfromLink || !isValidURL(platfromLink))
    ) {
      messageApi.open({
        type: "warning",
        content: t("no_available_link"),
        className: "message",
        icon: <HiInformationCircle />,
        duration: 3,
      });
    }
  };
  return (
    <>
      {contextHolder}
      <div className={styles.platform_item}>
        <Tooltip
          placement={"top"}
          trigger="hover"
          destroyTooltipOnHide={true}
          overlayStyle={{
            width: "auto",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          overlayClassName="platform_tooltip"
          title={window.matchMedia("(max-width:1200px)").matches
            ? "" : platfromLink && isValidURL(platfromLink) ? t("platform_profile", { socialPlatform: platformName }) : t("no_available_link")}
        >
          <a
            href={platfromLink && isValidURL(platfromLink) ? platfromLink : undefined}
            rel="noreferrer"
            target="_blank"
            onClick={handleOpenMessage}
          >
            <img src={platformImg} alt="" />
          </a>
        </Tooltip>
        <div className={styles.text_container}>
          <p className="title">
            {platformName}
            {isVerified ? <img className={styles.verfiedBadge} src={`/imgs/${platformKey.toLowerCase()}-badge.svg`} alt="verfied-flag" />
              : ""}
            {followersCount ? (followersCount > 0 && <span className="body">
              (
              {followersCount &&
                formatNumber(
                  followersCount
                )}{" "}
              {t("followers")})
            </span>) : ''}

          </p>
          {isValidURL(platfromLink) && (
            <a
              target="_blank"
              href={platfromLink}
              className={styles.platform_link}
              rel="noreferrer"
            >
              {t("view_profile")}
            </a>
          )}
          {/* {!isActive ? <div className={styles.warning}>
            <img src={WarningImg} />
            <p>{t('deactivated_sm_warning')}</p>
          </div> : ""} */}
        </div>
      </div>
    </>
  );
};
export default InfluencerDetailsPlatformItem;
