import React, { useEffect } from 'react'
import styles from "./index.module.scss"
import { useTranslation } from 'react-i18next';
import { HiOutlineDocumentText, HiOutlineUser, HiOutlineGlobeAsiaAustralia, HiOutlineMapPin, HiOutlineLanguage, HiOutlineUsers } from 'react-icons/hi2';
import { PiGenderIntersexLight, PiBaby } from "react-icons/pi";
import { GiDiamondRing } from "react-icons/gi";
import { LiaIdCardSolid } from "react-icons/lia";
import { PiCake } from "react-icons/pi";
import { HiOutlineFlag } from "react-icons/hi";
import { Tooltip } from 'antd';


const InfluencerBasicInfo = ({ details }: any) => {
    const {
        t,
        i18n: { dir },
    } = useTranslation();

    return <ul className={styles.basic_info + " " + "_lr-hide"} data-private>
        <li className={styles.item}>
            <div className={styles.iconContainer}>
                <LiaIdCardSolid />
                {details?.otherNames?.length ? <Tooltip className={styles.otherNames} overlayClassName="platform_tooltip"
                    title={t('other_names')}>{details?.otherNames.map((nam: any) => <span>{nam}</span>)}</Tooltip> : "-"}
            </div>
        </li>
        <li className={styles.item}>
            <div className={styles.iconContainer}>
                <span className={"icon-bio-1" + ' ' + styles.icon}></span>
                <Tooltip overlayClassName="platform_tooltip" title={t('bio')}>
                    <p className={'body' + " " + styles.text + " " + styles.bio}>{details?.bio ? details?.bio : "-"}</p>
                </Tooltip>
            </div>
        </li>
        <div className={styles.lowerSection}>
            {details.basicInfo.profileTypeKey == "Influencer" ? <div className={styles.iconContainer}>
                <HiOutlineFlag />
                <Tooltip overlayClassName="platform_tooltip"
                    title={t('nationality')}><p className={'body' + " " + styles.text}>{details?.nationality ? details?.nationality : "-"}</p></Tooltip>
            </div> : ""}
            {details.basicInfo.profileTypeKey == "Influencer" ? <div className={styles.iconContainer}>
                <PiGenderIntersexLight />
                {/* <p className='body'>{t('gender')}</p> */}
                <Tooltip overlayClassName="platform_tooltip" title={t('gender')}>
                    <p className={'body' + " " + styles.text}>{details?.gender ? details?.gender : "-"}</p>
                </Tooltip>
            </div> : ""}

            {<div className={styles.iconContainer}>
                <HiOutlineMapPin />
                <Tooltip overlayClassName="platform_tooltip" title={`${t('residency')}: ${details?.residency}`}>
                    <p className={'body' + " " + styles.text}>{details?.residency ? details?.residency : "-"}</p>
                </Tooltip>
            </div>}

            {details.basicInfo.profileTypeKey == "Influencer" ? <div className={styles.iconContainer}>
                <span className={"icon-age-limit" + ' ' + styles.icon}></span>
                <Tooltip overlayClassName="platform_tooltip" title={`${t('ageRange')}`}>
                    <p className={'body' + " " + styles.text}>{details?.ageRange ? details?.ageRange + " " + t('years') : "-"}</p>
                </Tooltip>
            </div> : ""}

            {details.basicInfo.profileTypeKey == "Influencer" ? <div className={styles.iconContainer}>
                <HiOutlineLanguage />
                <Tooltip overlayClassName="platform_tooltip" title={t('speaking_languages')}>
                    <p className={'body' + " " + styles.text + " " + styles.lang}>{details?.speakingLanguages ? details?.speakingLanguages.join(", ").slice(0, 10) + (details?.speakingLanguages.slice(0, 10).join(", ").length > 10 ? " ..." : "") : "-"}</p>
                </Tooltip>
                {details?.speakingLanguages?.join(", ").length > 10 ? <Tooltip trigger={'click'} overlayClassName="light_tooltip" title={details?.speakingLanguages.join(", ")}>
                    <span className={styles.langsCount}>+{details?.speakingLanguages.length > 1 ? details?.speakingLanguages.join(", ").slice(0, 10).includes(",") ? details?.speakingLanguages.join(", ").slice(10).split(",").length : details?.speakingLanguages.join(", ").slice(10).split(",").length - 1 : 1}</span>
                </Tooltip> : ""}
            </div> : ''}




            {details.basicInfo.profileTypeKey == "Influencer" ? <div className={styles.iconContainer}>
                <GiDiamondRing />
                {/* <p className='body'>{t('marital_status')}</p> */}
                <Tooltip overlayClassName="platform_tooltip" title={t('marital_status')}>
                    <p className={'body' + " " + styles.text}>{details?.maritalStatus ? details?.maritalStatus : "-"}</p>
                </Tooltip>

            </div> : ""}


            {details.basicInfo.profileTypeKey == "Influencer" ? <div className={styles.iconContainer}>
                <PiBaby />
                <Tooltip overlayClassName="platform_tooltip" title={t('children')}>
                    <p className={'body' + " " + styles.text}>{details.hasChildern == true ? t('yes') : details.hasChildern == false ? t('no') : "-"}</p>
                </Tooltip>
            </div> : ""}


            {details.basicInfo.profileTypeKey == "Influencer" ? <div className={styles.iconContainer}>
                <span className={"icon-pets" + ' ' + styles.icon}></span>
                <Tooltip overlayClassName="platform_tooltip" title={t('pets')}>
                    <p className={'body' + " " + styles.text}>{details.hasPets == true ? t('yes') : details.hasPets == false ? t('no') : "-"}</p>
                </Tooltip>

            </div> : ""}
        </div>


    </ul>



}

export default InfluencerBasicInfo