import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import './assets/fonts/fontello/css/fontello.css'
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./Utils/i18n/i18n";
import { AuthProvider } from "./Context/MainContext";
import getPalette from "tailwindcss-palette-generator";
import { getAgencyData } from "./Context/Actions/agency";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);





root.render(
  <I18nextProvider i18n={i18n}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
