import Service from "../Service/Index";
import styles from "./Index.module.scss";
import { Card, Collapse, Tooltip, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  HiInformationCircle,
  HiOutlineCheck,
  HiOutlineInformationCircle,
} from "react-icons/hi2";
import { formatNumber, isValidURL } from "../../../Utils/helpers";
import Panel from "antd/es/cascader/Panel";
import { HiOutlineX } from "react-icons/hi";
import WarningImg from "../../../assets/imgs/Attention.svg"


const PlatformServicesMobile = ({
  lisitingAssigned = false,
  state,
  socialPlatform,
  getData,
  doUpdate,
  allAcceptedLoading,
  allRejectedLoading,
  setDisableParentActions,
}: {
  getChanged?: (data: boolean) => void;
  lisitingAssigned?: boolean;
  state: string;
  socialPlatform: any;
  infId: any;
  getData: any;
  doUpdate: any;
  allAcceptedLoading: boolean;
  allRejectedLoading: boolean;
  setDisableParentActions: any;
}) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const handleOpenMessage = () => {
    if (
      window.matchMedia("(max-width:1200px)").matches &&
      (!socialPlatform.socialPlatformUrl ||
        !isValidURL(socialPlatform.socialPlatformUrl))
    ) {
      messageApi.open({
        type: "warning",
        content: t("no_available_link"),
        className: "message",
        icon: <HiInformationCircle />,
        duration: 3,
      });
    }
  };

  const getTitle = (
    isWideScreen: boolean,
    socialPlatform: any,
    isURLValid: boolean
  ): string => {
    if (isWideScreen) {
      if (isURLValid) {
        return t("platform_profile", {
          socialPlatform: socialPlatform.name,
        });
      } else {
        return t("no_available_link");
      }
    } else {
      return "";
    }
  };
  const checkHeaderServiceStatus = () => {
    const allPending = socialPlatform.services.every(
      (service: any) => service.bookingStatusKey === "Pending"
    );
    const somePending = socialPlatform.services.some(
      (service: any) => service.bookingStatusKey === "Pending"
    );
    const someRejected = socialPlatform.services.some(
      (service: any) => service.bookingStatusKey === "Rejected"
    );
    const someAccepted = socialPlatform.services.some(
      (service: any) => service.bookingStatusKey === "Accepted"
    );
    const allRejected = socialPlatform.services.every(
      (service: any) => service.bookingStatusKey === "Rejected"
    );
    if (allPending) {
      return (
        <span className={`label-warning-700-50 ${styles.status_text}`}>
          <HiOutlineInformationCircle /> {t("pending")}
        </span>
      );
    } else if (somePending && someAccepted) {
      return (
        <>
          <span className={`label-primary ${styles.status_text}`}>
            <HiOutlineCheck />
            {socialPlatform.services
              .filter((service: any) => service.bookingStatusKey === "Accepted")
              .map((service: any) => service.name)
              .join(" - ")}
          </span>
          <span className={`label-warning-700-50 ${styles.status_text}`}>
            <HiOutlineInformationCircle />
            {socialPlatform.services
              .filter((service: any) => service.bookingStatusKey === "Pending")
              .map((service: any) => service.name)
              .join(" - ")}
          </span>
        </>
      );
    } else if (somePending) {
      return (
        <span className={`label-warning-700-50 ${styles.status_text}`}>
          <HiOutlineInformationCircle />
          {socialPlatform.services
            .filter((service: any) => service.bookingStatusKey === "Pending")
            .map((service: any) => service.name)
            .join(" - ")}
        </span>
      );
    } else if (!somePending && someAccepted) {
      return (
        <span className={`label-primary ${styles.status_text}`}>
          <HiOutlineCheck />
          {socialPlatform.services
            .filter((service: any) => service.bookingStatusKey === "Accepted")
            .map((service: any) => service.name)
            .join(" - ")}
        </span>
      );
    } else if (allRejected) {
      return (
        <span className={`label-skipped ${styles.status_text}`}>
          <HiOutlineX /> {t("allRejected")}
        </span>
      );
    }
  };
  return (
    <>
      {contextHolder}
      <Collapse
        className="enhancedInfluencerCardCollapse"
        expandIconPosition="end"
        defaultActiveKey={[1]}
      >
        <Collapse.Panel
          header={
            <div className={styles.platform}>
              {/* <Tooltip
                placement={"top"}
                trigger="hover"
                destroyTooltipOnHide={true}
                overlayStyle={{
                  width: "auto",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                overlayClassName="platform_tooltip"
                title={getTitle(
                  window.matchMedia("(max-width:1200px)").matches,
                  socialPlatform,
                  isValidURL(socialPlatform.socialPlatformUrl)
                )}
              > */}
              <a
                href={
                  socialPlatform.socialPlatformUrl &&
                    isValidURL(socialPlatform.socialPlatformUrl)
                    ? socialPlatform.socialPlatformUrl
                    : undefined
                }
                rel="noreferrer"
                target="_blank"
                onClick={handleOpenMessage}
              >
                <img
                  loading="lazy"
                  src={socialPlatform.logoUrl}
                  alt="platform"
                />
              </a>
              {/* </Tooltip> */}

              <div className={styles.platformBasicInfo}>
                <p className={styles.socialPlatformName}>
                  {socialPlatform.name}

                  {socialPlatform.followersCount != 0 &&
                    socialPlatform.followersCount > 0 ? (
                    <span className={styles.followersCount}>
                      ({formatNumber(socialPlatform.followersCount)}{" "}
                      {t("followers")})
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                {/* {!socialPlatform.isActive ? <div className={styles.warning}>
                  <img src={WarningImg} />
                  <p>{t('deactivated_sm_warning')}</p>
                </div> : ""} */}
                <div className="headerServices">
                  {checkHeaderServiceStatus()}
                </div>
              </div>
            </div>
          }
          key="1"
        >
          {socialPlatform.services.map((s: any) => (
            <Card.Grid
              className={`cardGrid ${s.bookingStatusKey.toLowerCase() === "rejected" ? "dimmed" : ""
                }`}
              key={s.name}
              hoverable={false}
            >
              <Service
                data={s}
                lisitingAssigned={lisitingAssigned}
                key={s.advertisingProductId}
                name={s.name}
                state={state}
                currencyKey={s.currencyKey}
                getData={getData}
                doUpdate={doUpdate}
                allAcceptedLoading={allAcceptedLoading}
                allRejectedLoading={allRejectedLoading}
                setDisableParentActions={setDisableParentActions}
              />
            </Card.Grid>
          ))}
        </Collapse.Panel>
      </Collapse>
      {/* <Card
        className="platformCard"
        title={
          <div className={styles.platform}>
            <Tooltip
              placement={"top"}
              trigger="hover"
              destroyTooltipOnHide={true}
              overlayStyle={{
                width: "auto",
                fontFamily: "inherit",
                fontWeight: 400,
              }}
              overlayClassName="platform_tooltip"
              title={getTitle(
                window.matchMedia("(max-width:1200px)").matches,
                socialPlatform,
                isValidURL(socialPlatform.socialPlatformUrl)
              )}
            >
              <a
                href={
                  socialPlatform.socialPlatformUrl &&
                  isValidURL(socialPlatform.socialPlatformUrl)
                    ? socialPlatform.socialPlatformUrl
                    : undefined
                }
                rel="noreferrer"
                target="_blank"
                onClick={handleOpenMessage}
              >
                <img
                  loading="lazy"
                  src={socialPlatform.logoUrl}
                  alt="platform"
                />
              </a>
            </Tooltip>
            <p className={styles.socialPlatformName}>
              {socialPlatform.name}

              {socialPlatform.followersCount &&
                socialPlatform.followersCount > 0 && (
                  <span className={styles.followersCount}>
                    ({formatNumber(socialPlatform.followersCount)}{" "}
                    {t("followers")})
                  </span>
                )}
            </p>
          </div>
        }
      >
        {socialPlatform.services.map((s: any) => (
          <Card.Grid
            className={`cardGrid ${
              s.bookingStatusKey.toLowerCase() === "rejected" ? "dimmed" : ""
            }`}
            key={s.name}
            hoverable={false}
          >
            <Service
              data={s}
              lisitingAssigned={lisitingAssigned}
              key={s.advertisingProductId}
              name={s.name}
              state={state}
              currencyKey={s.currencyKey}
              getData={getData}
              doUpdate={doUpdate}
              allAcceptedLoading={allAcceptedLoading}
              allRejectedLoading={allRejectedLoading}
              setDisableParentActions={setDisableParentActions}
            />
          </Card.Grid>
        ))}
      </Card> */}
    </>
  );
};

export default PlatformServicesMobile;
