import PlatformServices from "./PlatformServices/Index";
import styles from "./index.module.scss";
import {
  HiBolt,
  HiInformationCircle,
  HiOutlineCheck,
  HiOutlineCheckCircle,
  HiOutlineChevronRight,
  HiOutlineXCircle,
  HiShieldCheck,
} from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import imgPlaceholder from "../../assets/imgs/influencer_placeholder.svg";
import { useContext, useEffect, useState } from "react";
import { influencerMappedData } from "../../Utils/helpers";
import { AuthStateContext } from "../../Context/MainContext";
import {
  Button,
  Dropdown,
  Space,
  Tooltip,
  notification,
  message,
  Collapse,
  CollapseProps,
} from "antd";
import { dir } from "i18next";
import { HiOutlineDotsVertical, HiOutlineX } from "react-icons/hi";
import {
  changeAllLineItemsStatus,
  getCampaignAssignedInfluencerDetails,
} from "../../Context/Actions/campaign";
import InfluencerDetails from "../InfluencerDetails";
import Service from "./Service/Index";
import PlatformServicesMobile from "./PlatformServicesMobile/Index";

const EnhancedInfluencerServicesCard = ({
  campaignId,
  campaignGuid,
  lisitingAssigned = false,
  state,
  influencer,
  setInfluencerMappedObj,
  getData,
  doUpdate,
}: {
  lisitingAssigned?: boolean;
  state: any;
  influencer: any;
  campaignId?: number | any;
  campaignGuid?: string;
  setInfluencerMappedObj?: (data: any) => void;
  getData?: (loading: boolean) => void;
  doUpdate: (loading: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { state: parentState, dispatch } = useContext(AuthStateContext);
  const [messageApi, contextHolder] = message.useMessage();
  const context = useContext(AuthStateContext);

  const handleImgError = (e: any) => {
    e.target.src = imgPlaceholder;
  };
  const [hasAllPending, setHasAllPending] = useState<boolean>(true);
  const [hasAllAccepted, setHasAllAccepted] = useState<boolean>(true);
  const [hasAllRejected, setHasAllRejected] = useState<boolean>(true);
  const [acceptedLoading, setAcceptedLoading] = useState<boolean>(false);
  const [rejectedLoading, setRejectedLoading] = useState<boolean>(false);
  const [servicesStatusArray, setServicesStatusArray] = useState<any>([]);

  const [acceptBtnTooltip, setAcceptBtnTooltip] = useState<any>(null);
  const [rejectBtnTooltip, setRejectBtnTooltip] = useState<any>(null);

  const [disableParentActions, setDisableParentActions] = useState(false);

  const [open, setOpen] = useState(false);

  const [influencerDetailsObj, setInfluencerDetailsObj] = useState<any>(null);

  const [detailsLoading, setDetailsLoading] = useState<boolean>(false);

  useState<boolean>(false);

  const getCampaignInfluencerDetails = async (openDetails: boolean = false) => {
    setDetailsLoading(true);
    try {
      const res = await getCampaignAssignedInfluencerDetails(
        influencer?.id,
        campaignId!
      );
      if (!res.error) {
        influencerMappedData(context.state, res).then((mappedObj: any) => {
          setInfluencerDetailsObj(mappedObj);
        });
      } else {
        openErrorNotification();
        setDetailsLoading(false);
      }
    } catch (err) {
      openErrorNotification();
      setDetailsLoading(false);
      return { error: true };
    }
    setDetailsLoading(false);
  };

  const openErrorNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("error_label"),
      description: t("something_went_wrong"),
      icon: <HiOutlineXCircle className="danger-text" />,
      className: "no-wrap",
    });
  };

  const showDrawer = async (e: any) => {
    setOpen(true);
    await getCampaignInfluencerDetails(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setInfluencerMappedObj && setInfluencerMappedObj(influencer);
    let allServicesArePending = true;
    let allServicesAreAccepted = true;
    let allServicesAreRejected = true;
    setAcceptBtnTooltip(null);
    setRejectBtnTooltip(null);
    let status: any = [];
    for (const platformKey in influencer.influencerSocialPlatforms) {
      const platform = influencer.influencerSocialPlatforms[platformKey];
      for (const serviceKey in platform.services) {
        const service = platform.services[serviceKey];
        status.push(service?.bookingStatusKey?.toLowerCase());
        if (service?.bookingStatusKey?.toLowerCase() !== "pending") {
          allServicesArePending = false;
        }
        if (service?.bookingStatusKey?.toLowerCase() !== "accepted") {
          allServicesAreAccepted = false;
        }
        if (service?.bookingStatusKey?.toLowerCase() !== "rejected") {
          allServicesAreRejected = false;
        }
        console.log(allServicesAreRejected, hasAllAccepted, service);
      }
    }
    setHasAllPending(allServicesArePending);
    setHasAllAccepted(allServicesAreAccepted);
    setHasAllRejected(allServicesAreRejected);
    setServicesStatusArray(status);

    const statusCount = status.reduce((count: any, status: any) => {
      count[status] = (count[status] || 0) + 1;
      return count;
    }, {});

    if (allServicesAreRejected) {
      setAcceptBtnTooltip(t("all_services_rejected"));
      setRejectBtnTooltip(t("all_services_rejected"));
    } else if (allServicesAreAccepted) {
      setAcceptBtnTooltip(t("all_services_accepted"));
      setRejectBtnTooltip(t("all_services_accepted"));
    } else if (
      statusCount.rejected > 0 &&
      !statusCount.accepted &&
      statusCount.pending > 0
    ) {
      setRejectBtnTooltip(null);
      setAcceptBtnTooltip(t("you_cant_accept_all"));
    } else if (
      !statusCount.rejected &&
      statusCount.accepted > 0 &&
      statusCount.pending > 0
    ) {
      setRejectBtnTooltip(t("you_cant_reject_all"));
      setAcceptBtnTooltip(null);
    } else if (
      statusCount.rejected > 0 &&
      statusCount.accepted > 0 &&
      statusCount.pending > 0
    ) {
      setRejectBtnTooltip(t("you_cant_reject_all"));
      setAcceptBtnTooltip(t("you_cant_accept_all"));
    } else if (
      statusCount.rejected > 0 &&
      statusCount.accepted > 0 &&
      !statusCount.pending
    ) {
      setRejectBtnTooltip(t("you_cant_reject_all"));
      setAcceptBtnTooltip(t("you_cant_accept_all"));
    } else {
      setAcceptBtnTooltip(null);
      setRejectBtnTooltip(null);
    }
  }, [influencer]);

  useEffect(() => {
    if (!parentState.loadingInf) {
      if (acceptedLoading) setAcceptedLoading(false);
      if (rejectedLoading) setRejectedLoading(false);
    }
  }, [parentState.loadingInf]);
  const acceptRejectAllLineItemsStatus = (status: string, device = "") => {
    if (device === "mobile") {
      if (status === "Accepted") {
        const content = acceptBtnTooltip;

        if (content) {
          messageApi.open({
            type: "warning",
            content: content,
            className: "message",
            icon: <HiInformationCircle />,
            duration: 3,
          });
        }
      } else {
        const content = rejectBtnTooltip;
        if (content) {
          messageApi.open({
            type: "warning",
            content: content,
            className: "message",
            icon: <HiInformationCircle />,
            duration: 3,
          });
        }
      }
    }
    if (
      (status === "Accepted" &&
        servicesStatusArray.find((x: any) => x === "rejected")?.length > 0) ||
      hasAllAccepted ||
      acceptedLoading ||
      rejectedLoading
    ) {
      return;
    } else if (
      (status === "Rejected" &&
        servicesStatusArray.find((x: any) => x === "accepted")?.length > 0) ||
      hasAllRejected ||
      acceptedLoading ||
      rejectedLoading
    ) {
      return;
    }
    dispatch({ type: "CHANGE_STATUS", payload: true });
    status === "Accepted" ? setAcceptedLoading(true) : setRejectedLoading(true);

    changeAllLineItemsStatus(campaignGuid!, influencer.id, status).then(
      (res) => {
        if (!res?.error) {
          getData!(true);
          doUpdate(true);
          notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: t("success_label"),
            description: t("services_status_changed"),
            icon: <HiOutlineCheckCircle className="success-text" />,
          });
        }
        if (res.error) {
          notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: t("error_label"),
            description: t("something_went_wrong"),
            icon: <HiOutlineXCircle className="danger-text" />,
            className: "no-wrap",
          });
        }
      }
    );
  };
  const menuProps = [
    {
      key: "Accepted",
      label: (
        <span
          onClick={() => acceptRejectAllLineItemsStatus("Accepted", "mobile")}
          className={`${styles.successOption} ${servicesStatusArray.find((x: any) => x === "rejected")?.length >
            0 ||
            hasAllAccepted ||
            disableParentActions
            ? styles.disabled
            : ""
            } ${parentState.changingStatus ? styles.disablePointers : ""}`}
        >
          {acceptedLoading ? (
            <span className="icon-loader spin"></span>
          ) : (
            <>
              <HiOutlineCheck />
              {t("accept_all")}
            </>
          )}
        </span>
      ),
    },
    {
      key: "Rejected",
      label: (
        <span
          onClick={() => acceptRejectAllLineItemsStatus("Rejected", "mobile")}
          className={`${styles.dangerOption} ${servicesStatusArray.find((x: any) => x === "accepted")?.length >
            0 ||
            hasAllRejected ||
            disableParentActions
            ? styles.disabled
            : ""
            } ${parentState.changingStatus ? styles.disablePointers : ""}`}
        >
          {rejectedLoading ? (
            <span className="icon-loader spin"></span>
          ) : (
            <>
              <HiOutlineX />
              {t("reject_all")}
            </>
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div
        className={`${styles.inf} influencerMob ${state === "editInfluencer" ? styles.infNoborderNoPadding : ""
          }`}
      >
        <div className={styles.infoContainer}>
          <div className={styles.basicInfo}>
            <div className={styles.imgContainer}>
              <img src={influencer.avatarUrl} alt="" onError={handleImgError} />
            </div>

            <div className={styles.info}>
              <p className={`title ${styles.infName}`}>
                <span>{influencer.name}</span>
                {influencer?.metaData?.isRecommended ? <span className={styles.recommended}><HiBolt className={styles.bolt} />
                  <span className={styles.txt}>{t('recommended')}</span></span> : ""}
                {/* {influencer.isFeatured ? <HiShieldCheck /> : ""} */}
              </p>
              {influencer.influencerNicheKeys && (
                <p className={`body ${styles.niches}`}>
                  {influencer.influencerNicheKeys
                    .map((n: any) => n.name)
                    .join(", ")}
                </p>
              )}
              <p
                onClick={(e: React.MouseEvent<HTMLDivElement>) => showDrawer(e)}
                className={`body ${styles.viewProfile}`}
              >
                {t("viewProfile")}{" "}
                <HiOutlineChevronRight className={styles.viewProfileIcon} />
              </p>
            </div>
          </div>
          <div className={styles.allActionsDesktop}>
            <Tooltip
              color={"#4B4B4B"}
              title={rejectBtnTooltip || ""}
              trigger="click"
              destroyTooltipOnHide={true}
              overlayStyle={{
                width: 205,
                fontSize: 14,
                fontFamily: "inherit",
                fontWeight: 400,
              }}
              overlayInnerStyle={{
                padding: 8,
              }}
            >
              <button
                onClick={() => acceptRejectAllLineItemsStatus("Rejected")}
                className={` lavad-btn lavad-btn-md lavad-btn-skipped-outline ${servicesStatusArray.find((x: any) => x === "accepted")
                  ?.length > 0 ||
                  hasAllRejected ||
                  parentState.changingStatus ||
                  disableParentActions
                  ? styles.disabled
                  : ""
                  } ${parentState.changingStatus ? styles.disablePointers : ""}`}
              >
                {rejectedLoading ? (
                  <span className="icon-loader spin"></span>
                ) : (
                  <>{t("reject_all")}</>
                )}
              </button>
            </Tooltip>
            <Tooltip
              color={"#4B4B4B"}
              title={acceptBtnTooltip || ""}
              trigger="click"
              destroyTooltipOnHide={true}
              overlayStyle={{
                width: 205,
                fontSize: 14,
                fontFamily: "inherit",
                fontWeight: 400,
              }}
              overlayInnerStyle={{
                padding: 8,
              }}
            >
              <button
                onClick={() => acceptRejectAllLineItemsStatus("Accepted")}
                className={` lavad-btn lavad-btn-md lavad-btn-primary ${servicesStatusArray.find((x: any) => x === "rejected")
                  ?.length > 0 ||
                  hasAllAccepted ||
                  acceptedLoading ||
                  rejectedLoading ||
                  disableParentActions
                  ? styles.disabled
                  : ""
                  } ${parentState.changingStatus ? styles.disablePointers : ""}`}
              >
                {acceptedLoading ? (
                  <span className="icon-loader spin"></span>
                ) : (
                  <>{t("accept_all")}</>
                )}
              </button>
            </Tooltip>
          </div>
          <div className={styles.allActionsMobile}>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: menuProps,
              }}
            >
              <Button className={styles.actionsDropdown}>
                <Space>
                  <HiOutlineDotsVertical />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className={styles.services}>
          {!window.matchMedia("(max-width:768px)").matches
            ? influencer.influencerSocialPlatforms.map((sp: any) => (
              <PlatformServices
                getData={getData}
                lisitingAssigned={lisitingAssigned}
                state={state}
                key={sp.key}
                socialPlatform={sp}
                infId={influencer.id}
                doUpdate={doUpdate}
                allAcceptedLoading={acceptedLoading}
                allRejectedLoading={rejectedLoading}
                setDisableParentActions={setDisableParentActions}
              />
            ))
            : influencer.influencerSocialPlatforms.map((sp: any) => (
              <PlatformServicesMobile
                getData={getData}
                lisitingAssigned={lisitingAssigned}
                state={state}
                key={sp.key}
                socialPlatform={sp}
                infId={influencer.id}
                doUpdate={doUpdate}
                allAcceptedLoading={acceptedLoading}
                allRejectedLoading={rejectedLoading}
                setDisableParentActions={setDisableParentActions}
              />
            ))}
        </div>
      </div>
      {open && (
        <InfluencerDetails
          container="body"
          influencerDetails={influencerDetailsObj}
          onClose={onClose}
          open={open}
          loading={detailsLoading}
        />
      )}
    </>
  );
};

export default EnhancedInfluencerServicesCard;
