import { useEffect, useContext } from 'react'
import Navbar from '../Components/Navbar/Navbar'
import { getLookups } from '../Context/Actions/lookups'
import { AuthStateContext } from '../Context/MainContext'
import i18n from "../Utils/i18n/i18n";

import { useTranslation } from "react-i18next";

import React from 'react';
import { notification } from 'antd';
import { HiOutlineXCircle } from 'react-icons/hi2';

import { remoteConfig } from "../Utils/firebase";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import styles from './layout.module.scss'

const AuthLayout = ({ children }: any) => {
    const { state, dispatch } = useContext(AuthStateContext);
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();


    const setDefaults = async () => {
        dispatch({ type: "REQUEST_LOOKUPS" })
        let lookupsVersion: any = null
        const res: any = await getLookups();
        if (res) {
            localStorage.setItem("lookups", JSON.stringify(res));
            fetchAndActivate(remoteConfig)
                .then(() => {
                    lookupsVersion = getValue(remoteConfig, process.env.REACT_APP_LOOKUPS_VERSION!)
                    console.log(getValue(remoteConfig, process.env.REACT_APP_LOOKUPS_VERSION!), "lookups_version_Dev");
                    localStorage.setItem(
                        "lookupsVersion",
                        lookupsVersion._value)

                })
                .catch((err) => {
                    console.log("Failed to fetch remote config", err);
                });

            dispatch({ type: "LOOKUPS_SUCCESS", payload: res });

        }
        if (res.error) {
            notification.open({
                placement: dir() === "rtl" ? "topLeft" : "topRight",
                message: t("error_label"),
                description: t("something_went_wrong"),
                icon: <HiOutlineXCircle className="danger-text" />,
                className: "no-wrap",
            });
        }

    }
    useEffect(() => {
        let lookupsVersion: any = null
        fetchAndActivate(remoteConfig)
            .then(() => {
                lookupsVersion = getValue(remoteConfig, process.env.REACT_APP_LOOKUPS_VERSION!)
                if (
                    !localStorage.getItem("lookups") ||
                    !JSON.parse(localStorage.getItem("lookups")!) ||
                    localStorage.getItem("lookupsVersion") !=
                    lookupsVersion._value) {
                    setDefaults();
                } else {
                    dispatch({ type: "STOP_LOADING" });

                }
            })
    }, []);



    useEffect(() => {
        i18n.on("languageChanged", () => {
            setDefaults();
        });
        return () => {
            // Clean up the event listener when the component unmounts
            i18n.off("languageChanged", () => {
                // setDefaults();
            });
        };
    }, [i18n]);

    return (
        <>
            <Navbar></Navbar>
            <div className={styles.layout}>
                {children}
            </div>
        </>
    )
}

export default AuthLayout;
