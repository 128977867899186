import React, { useContext, useState } from 'react'
import styles from './index.module.scss'
import { moneyFormatter } from '../../../../Utils/helpers'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip as Bartip, Legend, ResponsiveContainer, Text } from 'recharts';
import { HiOutlineExclamationTriangle } from 'react-icons/hi2';
import { Progress, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthStateContext } from '../../../../Context/MainContext';
import i18n from '../../../../Utils/i18n/i18n';
import SummaryLoader from '../../../../Components/helpers/SummaryLoader';


const CampaignSummary = ({ summary, loading, isMobile }: any) => {
    const [barHovered, setBarHovered] = useState(false);
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups },
        dispatch,
        state,
    } = useContext(AuthStateContext);

    let [barGraphData, setBarGraphData] = useState<any>({ width: 0 })


    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length && barHovered) {
            return (
                <div className="graph-tooltip" >
                    <div style={{ color: 'white' }}>{payload[0].payload.value} ({((payload[0].payload.value / summary?.influencers?.count) * 100).toFixed(0)}%)</div>
                </div>
            );
        }

        return null;
    };
    return (
        <div>
            {loading ? <div className='graph-spinner'><SummaryLoader /></div> : ""}

            <div className={`${styles.summary} ${loading ? styles.loading : ""}`}>

                <div className={styles.tot}>
                    <div>
                        <p>{t('total_cost')} ({t('sar')})</p>
                        <h2>{summary?.lineItems?.cost?.value ? moneyFormatter(summary?.lineItems?.cost?.value) : 0}</h2>
                        {summary?.lineItems?.cost?.excludedLineItemsCount ? <span><HiOutlineExclamationTriangle />{t('excluding')} <strong>{summary?.lineItems?.cost?.excludedLineItemsCount}</strong> {t('ads')}</span> : ""
                        }                    </div>
                    <section>
                        <div>
                            <p>{t('total_influencers')}</p>
                            <h2>{summary?.influencers?.count || 0}</h2>
                        </div>
                        <div>
                            <p>{t('total_ads')}</p>
                            <h2>{summary?.lineItems?.count || 0}</h2>
                        </div>
                    </section>
                </div>
                <div className={styles.inf}>
                    <div className={styles.withSeperators}>
                        <p className="bold">{t('influencer_gender')}</p>
                        <ul className={styles.gen}>
                            <li><Tooltip title={t('male')}><img src={`/imgs/male_sum.svg`} alt="" /></Tooltip><Tooltip title={t('male')}>{summary?.influencers?.distribution?.gender?.find((k: any) => k.Key == 'Male')?.count || 0}</Tooltip></li>
                            <li><Tooltip title={t('female')}> <img src={`/imgs/female_sum.svg`} alt="" /></Tooltip><Tooltip title={t('female')}> {summary?.influencers?.distribution?.gender?.find((k: any) => k.Key == 'Female')?.count || 0}</Tooltip></li>

                            <li><Tooltip title={t('other')}><img src={`/imgs/briefcase_sum.svg`} alt="" /></Tooltip><Tooltip title={t('other')}>{summary?.influencers?.distribution?.profileType?.find((k: any) => k.Key == 'GeneralAccount')?.count || 0}</Tooltip></li>


                        </ul>
                    </div>
                    <div className={styles.types}>
                        <p className="bold">{t('influencer_type')}</p>
                        <ul>
                            <li>
                                <div className={styles.types_tit}><span>{t('micro')}</span><span>{t('Micro_info')}</span></div>
                                <Tooltip title={summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Micro')?.count ? `${(((summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Micro')?.count || 0) / summary?.lineItems?.count) * 100).toFixed(0)}%` : ""}>
                                    <Progress percent={((summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Micro')?.count || 0) / summary?.lineItems?.count) * 100}
                                        size={[259, 16]}
                                        strokeLinecap="round"
                                        trailColor='#EAECF0'
                                        strokeColor="#48C3F8" />
                                </Tooltip>
                                <p>{summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Micro')?.count || 0}</p>
                            </li>
                            <li>
                                <div className={styles.types_tit}><span>{t('macro')}</span><span>{t('Macro_info')}</span></div>
                                <Tooltip title={summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Macro')?.count ? `${(((summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Macro')?.count || 0) / summary?.lineItems?.count) * 100).toFixed(0)}%` : ""}>
                                    <Progress percent={((summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Macro')?.count || 0) / summary?.lineItems?.count) * 100}
                                        size={[259, 16]}
                                        strokeLinecap="round"
                                        trailColor='#EAECF0'
                                        strokeColor="#48C3F8" />
                                </Tooltip>
                                <p>{summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Macro')?.count || 0}</p>
                            </li>
                            <li>
                                <div className={styles.types_tit}><span>{t('power')}</span><span>{t('Power_info')}</span></div>
                                <Tooltip title={summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Power')?.count ? `${(((summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Power')?.count || 0) / summary?.lineItems?.count) * 100).toFixed(0)}%` : ""}>

                                    <Progress percent={((summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Power')?.count || 0) / summary?.lineItems?.count) * 100}
                                        size={[259, 16]}
                                        strokeLinecap="round"
                                        trailColor='#EAECF0'
                                        strokeColor="#48C3F8" />
                                </Tooltip>
                                <p>{summary?.lineItems?.distribution?.influencertype?.find((k: any) => k.Key == 'Power')?.count || 0}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.graph}>
                    <p className="bold">{t('influencer_age_group')}</p>

                    <ResponsiveContainer className={!summary?.influencers?.distribution?.ageGroup.length ? "emptyGraph" : ""} width="100%" height="100%">

                        <BarChart

                            barSize={summary?.influencers?.distribution?.ageGroup.length == 1 ? "53" : "16"}
                            data={summary?.influencers?.distribution?.ageGroup.length ? summary?.influencers?.distribution?.ageGroup?.map((k: any) => ({ name: t(k.Key.toLowerCase()), value: k.count })) : isMobile ? lookups && lookups[i18n.language]?.ageRanges?.map((k: any) => ({ name: t(k.key.toLowerCase()), value: loading ? 0 : k.to ? k.to : 75 })).slice(0, 4) : lookups && lookups[i18n.language]?.ageRanges?.map((k: any) => ({ name: t(k.key.toLowerCase()), value: loading ? 0 : k.to ? k.to : 75 }))}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid stroke='#f5f5f5' strokeDasharray={0} />

                            <XAxis dataKey="name" stroke='transperant' />
                            <YAxis domain={[0, summary?.influencers?.count]} color='red' stroke='transperant' allowDecimals={false}
                            />
                            {summary?.influencers?.distribution?.ageGroup.length ? <Bartip cursor={{ fill: 'transparent' }} position={{ x: barGraphData.x - (barGraphData.width == 53 ? 25 : 40), y: barGraphData.y - 50 }} content={<CustomTooltip />} /> : ""}
                            <Bar
                                onMouseEnter={() => setBarHovered(true)}
                                onMouseLeave={() => setBarHovered(false)}
                                onMouseOver={(data) => {
                                    setBarGraphData(data)
                                }} radius={[4, 4, 0, 0]} dataKey="value" fill={summary?.influencers?.distribution?.ageGroup.length ? "#F84968" : "#eee"} activeBar={false} />

                        </BarChart>
                    </ResponsiveContainer>
                </div>

            </div>
        </div>

    )
}

export default CampaignSummary