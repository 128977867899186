import { Modal, Radio, RadioChangeEvent, Tabs, message, notification } from 'antd'
import styles from "./index.module.scss"
import { useTranslation } from 'react-i18next';
import XLS from "../../assets/imgs/xls-file.svg"
import PPT from "../../assets/imgs/ppt-file.svg"
import { useContext, useEffect, useState } from 'react';
import { AuthStateContext } from '../../Context/MainContext';
import { exportCampaignAssignedInfluencers } from '../../Context/Actions/campaign';
import { exportExcelInfs, generatePowerPointSlides, influencerMappedData, influencerMappedDataPPT, mapExportExcelInfsData } from '../../Utils/helpers';
import pptxgen from "pptxgenjs";
import i18n from '../../Utils/i18n/i18n';
import { HiOutlineCheckCircle } from 'react-icons/hi2';




const ExportModal = ({ modalOpen, setModalOpen, campaignDetails, campaginId, agencyDetails }: any) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups },
        dispatch,
        state,
    } = useContext(AuthStateContext);

    message.config({
        rtl: dir() === "rtl",
        prefixCls: "loading-toaster",
    });


    const [activeTab, setActiveTab] = useState("");
    const [withPrices, setWithPrices] = useState<boolean>(true);
    const [pptLang, setPptLang] = useState<string>("en");
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<any>("")

    const downloading = (loadingPPT: string) => {
        setLoading(loadingPPT)
    }
    message.config({
        top: 0,
    });
    useEffect(() => {
        if (loading == "loading") {
            message.open({
                type: 'loading',
                content: t('influencer_plan_downloading'),
                duration: 0,
            });
        }
        if (loading == "loaded") {
            message.destroy()
            if (!window.matchMedia("(max-width:768px)").matches) {
                notification.open({
                    placement: dir() === "rtl" ? "topLeft" : "topRight",
                    message: t("success_label"),
                    description: t("file_downloaded"),
                    icon: <HiOutlineCheckCircle className="success-text" />,
                    className: "no-wrap",
                });
            } else {
                message.open({
                    type: 'success',
                    content: t('file_downloaded'),
                });
            }
            setLoading("")
            setModalOpen(false)
        }
        if (loading == "invalid") {
            message.destroy()
            message.open({
                type: 'error',
                content: t('something_went_wrong'),
            });
            setLoading("")
            setModalOpen(false)

        }
    }, [loading])
    const exportXlsx = async () => {
        downloading("loading")
        const resPPt = await exportCampaignAssignedInfluencers(campaginId)
        const assignedInfluencersppt: any[] = [];
        if (resPPt.data.length > 0) {
            let influencers = resPPt.data;
            await Promise.all(
                influencers.map(async (influencer: any) => {
                    const mappedInfluencer = await influencerMappedData(
                        state,
                        influencer,
                        i18n.language
                    );
                    assignedInfluencersppt.push(mappedInfluencer);
                })
            );
        }
        let assignedInfluencers: any = [];
        assignedInfluencers = []
        await Promise.all(assignedInfluencersppt.map(async (influencer: any) => {
            const mappedInfluencer = await influencerMappedDataPPT(state, influencer, i18n.language);
            assignedInfluencers.push(mappedInfluencer);

        }));
        await exportExcelInfs(t, mapExportExcelInfsData(assignedInfluencers, lookups[i18n.language]), `Influencers Plan - ${campaignDetails?.name}.xlsx`)
        message.destroy()
        if (!window.matchMedia("(max-width:768px)").matches) {
            notification.open({
                placement: dir() === "rtl" ? "topLeft" : "topRight",
                message: t("success_label"),
                description: t("file_downloaded"),
                icon: <HiOutlineCheckCircle className="success-text" />,
                className: "no-wrap",
            });
        } else {
            message.open({
                type: 'success',
                content: t('file_downloaded'),
            });
        }
        setModalOpen(false)
    }
    const exportPPT = async () => {
        setLoading(true)
        const pptx = new pptxgen();
        const resPPt = await exportCampaignAssignedInfluencers(campaginId)
        const assignedInfluencersppt: any[] = [];
        if (resPPt.data.length > 0) {
            let influencers = resPPt.data;
            await Promise.all(
                influencers.map(async (influencer: any) => {
                    const mappedInfluencer = await influencerMappedData(
                        state,
                        influencer,
                        pptLang
                    );
                    assignedInfluencersppt.push(mappedInfluencer);
                })
            );
        }
        let assignedInfluencers: any = [];
        assignedInfluencers = []
        await Promise.all(assignedInfluencersppt.map(async (influencer: any) => {
            const mappedInfluencer = await influencerMappedDataPPT(state, influencer, pptLang);
            assignedInfluencers.push(mappedInfluencer);
            console.log(assignedInfluencers, "assignedInfluencersassignedInfluencers")

        }));

        await generatePowerPointSlides(
            pptx,
            assignedInfluencers,
            campaignDetails,
            pptLang,
            withPrices,
            downloading,
            agencyDetails
        );
    };

    const onClickHandler = () => {
        if (activeTab == 'xls') {
            exportXlsx()
        } else if (activeTab == 'ppt') {
            exportPPT()
        } else {
            setModalOpen(false)
        }
    }

    return (
        <Modal centered className='export-modal' title={t('export')} onCancel={() => setModalOpen(false)} open={modalOpen} footer={
            <div className='btn-group align-items-end'>
                <button onClick={() => setModalOpen(false)} className='lavad-btn lavad-btn-lg lavad-btn-ghost'>
                    {t('cancel')}
                </button>
                <button onClick={onClickHandler} disabled={!activeTab || loading} className='lavad-btn lavad-btn-lg lavad-btn-primary'>
                    {!activeTab ? t('select_format') : activeTab == 'xls' ? t('download_xls') : activeTab == 'ppt' ? t('download_ppt') : t('done')}
                </button>
            </div>

        }>
            <div className={styles.modalBody}>
                <ul className={styles.exportCont + ' ' + styles.desktopOnly}>
                    <li className={`${activeTab == 'xls' ? styles.active : ''}`} onClick={() => setActiveTab('xls')}>
                        <img src={XLS} alt='' />
                        <p>{t('.xls')}</p>
                    </li>
                    <li className={`${activeTab == 'ppt' ? styles.active : ''}`} onClick={() => setActiveTab('ppt')}>
                        <img src={PPT} alt='' />
                        <p>{t('.ppt')}</p>
                    </li>
                </ul>
                <Radio.Group onChange={e => setActiveTab(e.target.value)} className={styles.exportMobile + ' ' + styles.mobileOnly + ' ' + 'exportRadioGroup'}>
                    <div className={`${styles.radioContainer} ${activeTab == 'xls' ? styles.active : ''}`}>
                        <Radio className={`${styles.radioItem} exportRadio`} value={'xls'}><div className={styles.exportItem}>  <img src={XLS} alt='' />
                            <p>{t('.xls')}</p></div>
                        </Radio>
                        {activeTab == 'xls' ? <div className={styles.exportXls}>{t('export_xls')}</div> : ""}
                    </div>
                    <div className={`${styles.radioContainer} ${activeTab == 'ppt' ? styles.active : ''}`}>
                        <Radio className={`${styles.radioItem} exportRadio`} value={'ppt'}><div className={styles.exportItem}> <img src={PPT} alt='' />
                            <p>{t('.ppt')}</p></div>
                        </Radio>
                        {activeTab == 'ppt' ? <div className={styles.pptContainer}>
                            <div className={styles.pptOptions}>
                                <div className={styles.pptOption}>
                                    <h4 className="subtitle">{t("ask_for_pptType")}</h4>
                                    <Radio.Group
                                        className="revert-radio"
                                        onChange={(e: RadioChangeEvent) => setWithPrices(e.target.value)}
                                        value={withPrices}
                                    >
                                        <Radio value={true}>{t("services_with_prices")}</Radio>
                                        <Radio value={false}>{t("services_only")}</Radio>
                                    </Radio.Group>
                                </div>
                                <div className={styles.pptOption}>
                                    <h4 className="subtitle">{t("ask_for_pptLang")}</h4>
                                    <Radio.Group
                                        className="revert-radio"
                                        onChange={(e: RadioChangeEvent) => setPptLang(e.target.value)}
                                        value={pptLang}
                                    >
                                        <Radio value={"en"}>{t("english_label")}</Radio>
                                        <Radio value={"ar"}>{t("arabic_label")}</Radio>
                                    </Radio.Group>
                                </div>
                            </div></div> : ""}
                    </div>
                </Radio.Group>
                <div className={styles.desktopOnly + ' ' + styles.exportDetails}>
                    {activeTab == 'xls' ? <div className={styles.exportXls}>{t('export_xls')}</div> : ""}
                    {activeTab == 'ppt' ? <div className={styles.pptContainer}><div className={styles.pptContainer__header}>
                        <p>{t('export_ppt')}</p></div><div className={styles.pptOptions}>
                            <div className={styles.pptOption}>
                                <h4 className="subtitle">{t("ask_for_pptType")}</h4>
                                <Radio.Group
                                    className="revert-radio"
                                    onChange={(e: RadioChangeEvent) => setWithPrices(e.target.value)}
                                    value={withPrices}
                                >
                                    <Radio value={true}>{t("services_with_prices")}</Radio>
                                    <Radio value={false}>{t("services_only")}</Radio>
                                </Radio.Group>
                            </div>
                            <div className={styles.pptOption}>
                                <h4 className="subtitle">{t("ask_for_pptLang")}</h4>
                                <Radio.Group
                                    className="revert-radio"
                                    onChange={(e: RadioChangeEvent) => setPptLang(e.target.value)}
                                    value={pptLang}
                                >
                                    <Radio value={"en"}>{t("english_label")}</Radio>
                                    <Radio value={"ar"}>{t("arabic_label")}</Radio>
                                </Radio.Group>
                            </div>
                        </div></div> : ''}
                </div>
            </div>
        </Modal>
    )
}

export default ExportModal