import {
  Modal,
  notification,
  message,
  Button,
  Radio,
  RadioChangeEvent,
} from "antd";
import {
  Dispatch,
  useState,
  SetStateAction,
  useContext,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import styles from "./Index.module.scss";
import { HiOutlineDownload } from "react-icons/hi";
import {
  generatePowerPointSlides,
  influencerMappedData,
  influencerMappedDataPPT,
} from "../../Utils/helpers";
import pptxgen from "pptxgenjs";
import { AuthStateContext } from "../../Context/MainContext";
import { exportCampaignAssignedInfluencers } from "../../Context/Actions/campaign";

const PPTExport = ({
  modalOpen,
  setModalOpen,
  onSuccess,
  campaignDetails,
  campaginId
}: {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  campaignDetails: any;
  campaginId: any;
}) => {
  const {
    t,
    i18n: { dir, language },
  } = useTranslation();
  const {
    state: { lookups },
    dispatch,
    state,
  } = useContext(AuthStateContext);
  message.config({
    rtl: dir() === "rtl",
    prefixCls: "loading-toaster",
  });

  const [loading, setLoading] = useState<any>("");
  const [withPrices, setWithPrices] = useState<boolean>(true);
  const [pptLang, setPptLang] = useState<string>("en");


  const cancel = () => {
    setModalOpen(false);
  };
  const downloading = (loadingPPT: string) => {
    setLoading(loadingPPT)
  }
  const openNotification = () => {
    notification.config({
      duration: 2,
      maxCount: 4,
    });
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("success_label"),
      description: t("success_edit_campaign_link_status"),
      icon: <HiOutlineCheckCircle className="success-text" />,
      className: "no-wrap",
    });
  };
  const [messageApi, contextHolder] = message.useMessage();
  message.config({
    top: 0,
  });
  useEffect(() => {

    if (loading == "loading") {
      message.open({
        type: 'loading',
        content: t('influencer_plan_downloading'),
        duration: 0,
      });
    }
    if (loading == "loaded") {
      message.destroy()
      message.open({
        type: 'success',
        content: t('file_downloaded'),
      });
      setLoading("")
      setModalOpen(false)
    }
    if (loading == "invalid") {
      message.destroy()
      message.open({
        type: 'error',
        content: t('something_went_wrong'),
      });
      setLoading("")
      setModalOpen(false)

    }
  }, [loading])
  const exportPPT = async () => {
    setLoading(true)
    const pptx = new pptxgen();
    const resPPt = await exportCampaignAssignedInfluencers(campaginId)
    const assignedInfluencersppt: any[] = [];
    if (resPPt.data.length > 0) {
      let influencers = resPPt.data;
      await Promise.all(
        influencers.map(async (influencer: any) => {
          const mappedInfluencer = await influencerMappedData(
            state,
            influencer,
            pptLang
          );
          assignedInfluencersppt.push(mappedInfluencer);
        })
      );
    }
    let assignedInfluencers: any = [];
    assignedInfluencers = []
    await Promise.all(assignedInfluencersppt.map(async (influencer: any) => {
      const mappedInfluencer = await influencerMappedDataPPT(state, influencer, pptLang);
      assignedInfluencers.push(mappedInfluencer);
      console.log(assignedInfluencers, "assignedInfluencersassignedInfluencers")

    }));

    await generatePowerPointSlides(
      pptx,
      assignedInfluencers,
      campaignDetails,
      pptLang,
      withPrices,
      downloading,
      {}
    );
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={cancel}
      className="editCampaignModal editCampaignModal--noheight"
      centered
      title={t("export_ppt")}
      footer={() => (
        <>
          <Button
            className="lavad-btn lavad-btn-md lavad-btn-ghost"
            onClick={cancel}
          >
            {t("cancel")}
          </Button>
          <Button
            className={`lavad-btn lavad-btn-md lavad-btn-primary btn-icon`}
            disabled={loading == "loading"}
            htmlType="submit"
            onClick={exportPPT}
            icon={<HiOutlineDownload />}
          >

            <span>{t("export")}</span>
          </Button>
        </>
      )}
    >
      <div className={styles.pptOptions}>
        <div className={styles.pptOption}>
          <h4 className="subtitle">{t("ask_for_pptType")}</h4>
          <Radio.Group
            className="revert-radio"
            onChange={(e: RadioChangeEvent) => setWithPrices(e.target.value)}
            value={withPrices}
          >
            <Radio value={true}>{t("services_with_prices")}</Radio>
            <Radio value={false}>{t("services_only")}</Radio>
          </Radio.Group>
        </div>
        <div className={styles.pptOption}>
          <h4 className="subtitle">{t("ask_for_pptLang")}</h4>
          <Radio.Group
            className="revert-radio"
            onChange={(e: RadioChangeEvent) => setPptLang(e.target.value)}
            value={pptLang}
          >
            <Radio value={"en"}>{t("english_label")}</Radio>
            <Radio value={"ar"}>{t("arabic_label")}</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
};

export default PPTExport;
