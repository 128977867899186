import { Navigate } from "react-router-dom";
import { useAppContext } from "./Context/MainContext";
import AuthLayout from "./layout/AuthLayout";
import React from "react";

function PrivateRoute({ children }: any) {
  const { state } = useAppContext();
  // Check if the user is authenticated, e.g., using a token in context
  return  <AuthLayout>{children}</AuthLayout> 
}

export default PrivateRoute;
