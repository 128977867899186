import api from "../../Utils/axiosInterceptor";
import { config } from "../../Utils/config";

export interface Campaign {
  name: string;
  clientLink?: string;
  clientLinkActive?: boolean;
  brief: string;
  campaignObjectiveKey: string;
  brandsId: number;
  influencerTypes: string[];
  socialPlatformServices: string[];
}

export interface CampaignFilter {
  campaignObjective?: Array<string>;
  campaignStatus?: Array<string>;
  brandId?: Array<number>;
  keyword?: string;
  page: number;
  pageSize: number;
  from?: number;
  to?: number;
}

export interface CampaginListingItem {
  id: number;
  name: string;
  brief: string;
  campaignStatus: {
    id: number;
    name: string;
  };
  brand: {
    id: number;
    name: string;
    logo: string;
  };
  campaignObjective: {
    id: number;
    name: string;
  };
}

export interface CampaignDetailsInterface {
  error?: boolean | string;
  name?:string;
  agencyInfo: {
    basicInfo: {
      id: number,
      name: string
    }
  }
  auditSummary?: {
    modificationInfo?: {
      modificationDate: number;
    };
  };
  briefAndAttachments: {
    brief: string;
    influencerDonts: string;
    influencerDos: string;
    attachments: String[]
  }
  budgetAndTeam: {
    budget: string,
    campaignLeadId: string;
    campaignAssignedMembers: {
      id: string,
      name: string,
      email: string
    }[]
  }
  campaignSummary: {
    brand: {
      id: number;
      name: {
        en: string;
        ar: string;
      };
      logo: string;
      websiteUrl: string;
    },
    campaignObjectiveKey: string,
    campaignStatusKey: string,
    id: string,
    name: string,
    client: {
      id: number,
      name: string
    }
  }
  influencerAndServices: {
    campaignNicheKeys: any;
    influencerTypeKeys: any;
    requestedInfluencers: number
    socialPlatforms:
    | Array<{
      key: string;
      servicesKeys: string[];
    }>
    | any;
  }
  shareLink: {
    status: boolean,
    url: string
  }
}

export const createCampaign = async (campaign: Campaign) => {
  const res = await api.post(
    `${process.env.REACT_APP_API_URL}api/campaign`,
    campaign
  );
  if (res.data) {
    return res.data;
  } else {
    return { error: true };
  }
};
export const getAllCampaigns = async (params: CampaignFilter) => {
  let keyword = null;
  let brandId = null;
  let campaignStatus = null;
  let campaignObjective = null;
  let from = null;
  let to = null;

  params.keyword && (keyword = encodeURIComponent(params.keyword));

  params.brandId &&
    params.brandId.length > 0 &&
    (brandId = params.brandId.map((b: any) => `&brandId=${b}`).join(""));

  params.campaignObjective &&
    params.campaignObjective.length > 0 &&
    (campaignObjective = params.campaignObjective
      .map((c: any) => `&campaignObjective=${c}`)
      .join(""));

  params.campaignStatus &&
    params.campaignStatus.length > 0 &&
    (campaignStatus = params.campaignStatus
      .map((s: any) => `&campaignStatus=${s}`)
      .join(""));

  params.from && (from = params.from);

  params.to && (to = params.to);

  const res = await api.get(`${process.env.REACT_APP_API_URL
    }api/campaign/all?page=${params.page}&pageSize=${params.pageSize}${keyword && keyword !== "" ? `&keyword=${keyword}` : ""
    }${brandId && brandId !== "" ? brandId : ""}${campaignObjective && campaignObjective !== "" ? campaignObjective : ""
    }${campaignStatus && campaignStatus !== "" ? campaignStatus : ""}${from ? `&from=${from}` : ""
    }${to ? `&to=${to}` : ""}
    `);
  if (res.data) {
    return res.data;
  } else {
    return { error: true };
  }
};
export const getCampaign = async (campaignId: string) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/p/campaign/${campaignId}`
    );
    if (res?.data) {
      return res.data;
    } else {
    }
  } catch (err: any) {
    return err.response?.data?.errors[0].field == "id"
      ? { error: "access_denied" }
      : { error: "generic_error" };
  }
};
export const getCampaignAssignedInfluencers = async (campaignId: any, filters: any) => {
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/p/campaign/${campaignId}/influencer/all`
      , {
        ...(filters.status || filters.platform ? {
          "filters": {
            ...(filters.status ? {
              "lineItemBookingStatuses": [
                filters.status
              ]
            } : {}),
            ...(filters.platform ? {
              "socialPlatformKeys": [
                filters.platform
              ]
            } : {})
          }
        } : {})
      });
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const changeLineItemStatus = async (
  lineItemId: number,
  status: string
) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/p/campaign/lineitem/${lineItemId}/${status}`
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
};
export const changeAllLineItemsStatus = async (
  campaignId: string,
  influencerId: number,
  status: string
) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/p/campaign/${campaignId}/influencer/${influencerId}/lineitem/all/${status}`
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
};
export const getCampaignAssignedInfluencerDetails = async (influencerId: number, campaignId: string) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL
      }api/p/influencer/${influencerId}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
}

export const exportCampaignAssignedInfluencers = async (campaignId: any) => {

  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL
      }api/p/campaign/${campaignId}/influencer/export/all`
      , {
        "filters": {
          "lineItemBookingStatuses": [
            "Accepted", "Pending"
          ]
        }
      });
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const getCampaignSummary = async (campaignId: any, filters: any) => {

  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL
      }api/p/campaign/${campaignId}/summary`
      , {
        ...(filters.status || filters.platform ? {
          "filters": {
            ...(filters.status ? {
              "lineItemBookingStatuses": [
                filters.status
              ]
            } : {}),
            ...(filters.platform ? {
              "socialPlatformKeys": [
                filters.platform
              ]
            } : {})
          }
        } : {})
      });
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};